import React from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import MainComponent from './MainComponent';
import { InValidURL } from './InValidURL';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Routes = () => {
  const query = useQuery();
  const InvalidURLMsge = "There is no data with the mentioned URL, please provide a valid one";

  return (
    <Router>
      <Switch>
        {/* Route for /:param */}
        <Route
          exact
          path="/:param?"
          component={(routeProps) => {
            const defaultParam = query.get('i') || routeProps.match.params.param; // Get the 'i' query parameter or the path param
            return defaultParam === null ? (
              <InValidURL msg={InvalidURLMsge} errLoading={true} />
            ) : (
              <MainComponent param={defaultParam} {...routeProps} />
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
