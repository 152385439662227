import './App.css';
import Questionnaire from "./Components/Questionnaire";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Container, Row, Col } from "react-bootstrap";
import { InValidURL } from './Components/InValidURL';
import { API_END_POINT } from './config';

export default function HomeAppComponent({ word, Latitude, Longitude, wholeWord }) {
    const [displayQuestions, setDisplayQuestions] = useState([]);
    const [displayLogicQuestions, setDisplayLogicQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [errLoading, seterrLoading] = useState(false);
    const InvalidURLMsge = "There is no data with mentioned url,</br> please provide a valid one";
    // dummy survey code
    const survey = {
        "surveyid": "8",
        "surveycode": "bb",
        "surveyname": "Postpaid Billing Experience",
        "surveyheadingForeground": {
            "margin": "auto",
            "color": "#FFF",
            "font-size": "27px"
        },
        "surveyheadingBackground": {
            "background-color": "red",
            "padding": "10px",
            "text-align": "start"
        },
        "surveyheading": "Postpaid Billing Experience",
        "survey": {
            "questions": [
                {
                    "id": "1",
                    "code": "Q1",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q1. Please select your preferred language",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "English,Hindi,Tamil,Bengali,Oriya,Marathi,Assamese,Telugu,Gujarati,Kannada,Malayalam",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 1
                },
                {
                    "id": "2",
                    "code": "Q2",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q2. Considering your recent <b>Billing Experience of Airtel Postpaid</b>, how likely are you to <b>recommend Airtel Postpaid</b> to friends/ family?",
                        "Malayalam": "Q2. എയർടെൽ പോസ്റ്റ്‌പെയ്ഡിന്റെ സമീപകാല ബില്ലിംഗ് അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, സുഹൃത്തുക്കൾക്കും കുടുംബാംഗങ്ങൾക്കും എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്?",
                        "Kannada": "Q2. ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಬಿಲ್ಲಿಂಗ್ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆ ಎಷ್ಟು?",
                        "Gujarati": "Q2. એરટેલ પોસ્ટપેડના તમારા તાજેતરના બિલિંગ અનુભવને ધ્યાનમાં લેતા, તમે મિત્રો/કુટુંબને એરટેલ પોસ્ટપેડની ભલામણ કરો તેવી કેટલી શક્યતા છે?",
                        "Telugu": "Q2. ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్ యొక్క మీ ఇటీవలి బిల్లింగ్ అనుభవాన్ని పరిశీలిస్తే, మీరు ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్‌ని స్నేహితులు/కుటుంబ సభ్యులకు ఎంతవరకు సిఫార్సు చేయవచ్చు?",
                        "Assamese": "Q2. শেহতীয়াকৈ এয়াৰটেল পোষ্টপেইডৰ বিলিং অভিজ্ঞতা বিবেচনা কৰিলে, আপুনি বন্ধু/পৰিয়ালক এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ দিয়াৰ সম্ভাৱনা কিমান?",
                        "Marathi": "Q2. एअरटेल पोस्टपेडचा तुमचा अलीकडील बिलिंग अनुभव लक्षात घेता, तुम्ही मित्र/परिवाराला एअरटेल पोस्टपेडची सिफारिश करण्याची किती शक्यता आहे?",
                        "Oriya": "Q2. ଏୟାରଟେଲ ପୋଷ୍ଟପେଡର ତୁମର ସାମ୍ପ୍ରତିକ ବିଲିଂ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ତୁମେ ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ ପୋଷ୍ଟପେଡକୁ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q2. এয়ারটেল পোস্টপেইড এর সাম্প্রতিক বিলিং অভিজ্ঞতার কথা বিবেচনা করে, আপনি বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইড সুপারিশ করার সম্ভাবনা কতটা?",
                        "Tamil": "Q2. ஏர்டெல் போஸ்ட்பெய்டின் சமீபத்திய பில்லிங் அனுபவத்தைக் கருத்தில் கொண்டு, நண்பர்கள்/குடும்பத்தினருக்கு ஏர்டெல் போஸ்ட்பெய்டைப் பரிந்துரைப்பது எவ்வளவு சாத்தியம்?",
                        "Hindi": "Q2. एयरटेल पोस्टपेड के आपके हालिया बिलिंग अनुभव को ध्यान में रखते हुए, दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 2
                },
                {
                    "id": "3",
                    "code": "Q3",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q3. You rated <span style=\"color:blue;\">{Q2}</span> on Billing Experience of Airtel POSTPAID. Please let us know what all can be improved.",
                        "Malayalam": "Q3. നിങ്ങൾ റേറ്റുചെയ്തു <span style=\"color:blue;\">{Q3}</span> എയർടെൽ പോസ്റ്റ്പെയ്ഡിന്റെ ബില്ലിംഗ് അനുഭവം. എല്ലാം മെച്ചപ്പെടുത്താൻ കഴിയുന്നതെന്താണെന്ന് ദയവായി ഞങ്ങളെ അറിയിക്കുക. ",
                        "Kannada": "Q3. ಒಳಗೆ <span style=\"color:blue;\">{Q3}</span> ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ಬಿಲ್ಲಿಂಗ್ ಅನುಭವದ ಕುರಿತು. ಎಲ್ಲವನ್ನು ಸುಧಾರಿಸಬಹುದು ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ನಮಗೆ ತಿಳಿಸಿ. ",
                        "Gujarati": "Q3.તમે રેટ કર્યું છે <span style=\"color:blue;\">{Q3}</span> એરટેલ પોસ્ટપેડના બિલિંગ અનુભવ પર. કૃપા કરીને અમને જણાવો કે બધું શું સુધારી શકાય છે.  ",
                        "Telugu": "Q3.మీరు రేట్ చేసారు <span style=\"color:blue;\">{Q3}</span> ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్ యొక్క బిల్లింగ్ అనుభవంపై. దయచేసి అన్నింటినీ మెరుగుపరచగలరో మాకు తెలియజేయండి.",
                        "Assamese": "Q3.আপুনি ৰেটিং দিলে <span style=\"color:blue;\">{Q3}</span> এয়াৰটেল পোষ্টপেইডৰ বিলিং অভিজ্ঞতাৰ ওপৰত। সকলো কি কি উন্নত কৰিব পাৰি আমাক জনাওক। ",
                        "Marathi": "Q3.तुम्ही रेट केले <span style=\"color:blue;\">{Q3}</span> एअरटेल पोस्टपेडच्या बिलिंग अनुभवावर. कृपया आम्हाला कळवा की सर्व काय सुधारले जाऊ शकते.  ",
                        "Oriya": "Q3. ଆପଣ ମୂଲ୍ୟାୟନ କରିଛନ୍ତି <span style=\"color:blue;\">{Q3}</span> ଏୟାରଟେଲ ପୋଷ୍ଟପେଡର ବିଲିଂ ଅଭିଜ୍ଞତା ଉପରେ | କ’ଣ ଉନ୍ନତି ହୋଇପାରିବ ଦୟାକରି ଆମକୁ ଜଣାନ୍ତୁ | ",
                        "Bengali": "Q3. আপনি রেটিং <span style=\"color:blue;\">{Q3}</span> এয়ারটেল পোস্টপেইডের বিলিং এক্সপেরিয়েন্স নিয়ে। দয়া করে আমাদের জানান যে, কী কী উন্নতি করা যেতে পারে। ",
                        "Tamil": "Q3. நீங்கள் மதிப்பிட்டுள்ளீர்கள் <span style=\"color:blue;\">{Q3}</span> ஏர்டெல் போஸ்ட்பெய்டின் பில்லிங் அனுபவம். எதையெல்லாம் மேம்படுத்தலாம் என்பதை எங்களுக்குத் தெரியப்படுத்துங்கள். ",
                        "Hindi": "Q3. आपने मूल्यांकन किया <span style=\"color:blue;\">{Q3}</span> एयरटेल पोस्टपेड के बिलिंग अनुभव पर। कृपया हमें बताएं कि क्या सुधार किया जा सकता है। "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 3
                },
                {
                    "id": "4",
                    "code": "Q3",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q3. You rated <span style=\"color:blue;\">{Q2}</span> on Billing Experience of Airtel POSTPAID. Please let us know the reason for the same.",
                        "Malayalam": "Q3. നിങ്ങൾ റേറ്റുചെയ്തു <span style=\"color:blue;\">{Q3}</span> എയർടെൽ പോസ്റ്റ്പെയ്ഡിന്റെ ബില്ലിംഗ് അനുഭവം. അതിനുള്ള കാരണം ദയവായി ഞങ്ങളെ അറിയിക്കുക. ",
                        "Kannada": "Q3. ನೀವು ರೇಟ್ ಮಾಡಿದ್ದೀರಿ <span style=\"color:blue;\">{Q3}</span> ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ಬಿಲ್ಲಿಂಗ್ ಅನುಭವದ ಕುರಿತು. ದಯವಿಟ್ಟು ಅದೇ ಕಾರಣವನ್ನು ನಮಗೆ ತಿಳಿಸಿ. ",
                        "Gujarati": "Q3.તમે રેટ કર્યું છે <span style=\"color:blue;\">{Q3}</span> એરટેલ પોસ્ટપેડના બિલિંગ અનુભવ પર. કૃપા કરીને અમને તેનું કારણ જણાવો. ",
                        "Telugu": "Q3.మీరు రేట్ చేసారు <span style=\"color:blue;\">{Q3}</span> ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్ యొక్క బిల్లింగ్ అనుభవంపై. దయచేసి అదే కారణాన్ని మాకు తెలియజేయండి.",
                        "Assamese": "Q3.আপুনি ৰেটিং দিলে <span style=\"color:blue;\">{Q3}</span> এয়াৰটেল পোষ্টপেইডৰ বিলিং অভিজ্ঞতাৰ ওপৰত। একেটা কাৰণে আমাক জনাব। ",
                        "Marathi": "Q3.तुम्ही रेट केले <span style=\"color:blue;\">{Q3}</span> एअरटेल पोस्टपेडच्या बिलिंग अनुभवावर. कृपया याचे कारण आम्हाला कळवा. ",
                        "Oriya": "Q3. ଆପଣ ମୂଲ୍ୟାୟନ କରିଛନ୍ତି <span style=\"color:blue;\">{Q3}</span> ଏୟାରଟେଲ ପୋଷ୍ଟପେଡର ବିଲିଂ ଅଭିଜ୍ଞତା ଉପରେ | ସମାନ କାରଣ ପାଇଁ ଦୟାକରି ଆମକୁ ଜଣାନ୍ତୁ |  ",
                        "Bengali": "Q3. আপনি রেটিং <span style=\"color:blue;\">{Q3}</span> এয়ারটেল পোস্টপেইডের বিলিং এক্সপেরিয়েন্স নিয়ে। দয়া করে এর কারণটা আমাদের জানাবেন। ",
                        "Tamil": "Q3. நீங்கள் மதிப்பிட்டுள்ளீர்கள் <span style=\"color:blue;\">{Q3}</span> ஏர்டெல் போஸ்ட்பெய்டின் பில்லிங் அனுபவம். அதற்கான காரணத்தை எங்களுக்குத் தெரியப்படுத்தவும்.",
                        "Hindi": "Q3. आपने मूल्यांकन किया <span style=\"color:blue;\">{Q3}</span> एयरटेल पोस्टपेड के बिलिंग अनुभव पर। कृपया हमें इसका कारण बताएं। "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            7,
                            8
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 4
                },
                {
                    "id": "5",
                    "code": "Q3",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q3. You rated <span style=\"color:blue;\">{Q2}</span> on Billing Experience of Airtel POSTPAID. Please let us know what all did you like the most.",
                        "Malayalam": "Q3. നിങ്ങൾ റേറ്റുചെയ്തു <span style=\"color:blue;\">{Q3}</span> എയർടെൽ പോസ്റ്റ്പെയ്ഡിന്റെ ബില്ലിംഗ് അനുഭവം. നിങ്ങൾ ഏറ്റവും കൂടുതൽ ഇഷ്ടപ്പെട്ടത് എന്താണെന്ന് ദയവായി ഞങ്ങളെ അറിയിക്കുക. ",
                        "Kannada": "Q3. ನೀವು ರೇಟ್ ಮಾಡಿದ್ದೀರಿ <span style=\"color:blue;\">{Q3}</span> ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ಬಿಲ್ಲಿಂಗ್ ಅನುಭವದ ಕುರಿತು. ನೀವು ಯಾವುದನ್ನು ಹೆಚ್ಚು ಇಷ್ಟಪಟ್ಟಿದ್ದೀರಿ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ನಮಗೆ ತಿಳಿಸಿ. ",
                        "Gujarati": "Q3.તમે રેટ કર્યું છે <span style=\"color:blue;\">{Q3}</span> એરટેલ પોસ્ટપેડના બિલિંગ અનુભવ પર. કૃપા કરીને અમને જણાવો કે તમને બધાને સૌથી વધુ શું ગમ્યું. ",
                        "Telugu": "Q3.మీరు రేట్ చేసారు <span style=\"color:blue;\">{Q3}</span> ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్ యొక్క బిల్లింగ్ అనుభవంపై. దయచేసి మీకు ఏది బాగా నచ్చిందో మాకు తెలియజేయండి.  ",
                        "Assamese": "Q3.আপুনি ৰেটিং দিলে <span style=\"color:blue;\">{Q3}</span> এয়াৰটেল পোষ্টপেইডৰ বিলিং অভিজ্ঞতাৰ ওপৰত। আপোনালোকে সকলোৱে কি বেছি ভাল লাগিল আমাক জনাওক।  ",
                        "Marathi": "Q3.तुम्ही रेट केले <span style=\"color:blue;\">{Q3}</span> एअरटेल पोस्टपेडच्या बिलिंग अनुभवावर. कृपया आम्हाला कळवा की तुम्हाला सर्वात जास्त काय आवडले.",
                        "Oriya": "Q3. ଆପଣ ମୂଲ୍ୟାୟନ କରିଛନ୍ତି <span style=\"color:blue;\">{Q3}</span> ଏୟାରଟେଲ ପୋଷ୍ଟପେଡର ବିଲିଂ ଅଭିଜ୍ଞତା ଉପରେ | ଆପଣ ସମସ୍ତଙ୍କୁ ଅଧିକ ପସନ୍ଦ କରୁଥିବା ଦୟାକରି ଆମକୁ ଜଣାନ୍ତୁ | ",
                        "Bengali": "Q3. আপনি রেটিং <span style=\"color:blue;\">{Q3}</span> এয়ারটেল পোস্টপেইডের বিলিং এক্সপেরিয়েন্স নিয়ে। দয়া করে আমাদের জানান যে, আপনার কোনটা সবচেয়ে বেশি পছন্দ হয়েছে। ",
                        "Tamil": "Q3. நீங்கள் மதிப்பிட்டுள்ளீர்கள் <span style=\"color:blue;\">{Q3}</span> ஏர்டெல் போஸ்ட்பெய்டின் பில்லிங் அனுபவம். நீங்கள் மிகவும் விரும்பிய அனைத்தையும் எங்களுக்குத் தெரியப்படுத்தவும்.",
                        "Hindi": "Q3. आपने मूल्यांकन किया <span style=\"color:blue;\">{Q3}</span> एयरटेल पोस्टपेड के बिलिंग अनुभव पर। कृपया हमें बताएं कि आपको सबसे ज्यादा क्या पसंद आया।  "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            9,
                            10
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 5
                },
                {
                    "id": "6",
                    "code": "Q4",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q4.  Do you pay the bill for this Airtel Postpaid connection4",
                        "Malayalam": "Q4. ഈ എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് കണക്ഷന്റെ ബിൽ നിങ്ങൾ അടയ്ക്കുന്നുണ്ടോ?",
                        "Kannada": "Q4. ಈ ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಸಂಪರ್ಕಕ್ಕಾಗಿ ನೀವು ಬಿಲ್ ಪಾವತಿಸುತ್ತೀರಾ?",
                        "Gujarati": "Q4. શું તમે આ એરટેલ પોસ્ટપેડ કનેક્શન માટે બિલ ચૂકવો છો?\n",
                        "Telugu": "Q4. మీరు ఈ ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్ కనెక్షన్ కోసం బిల్లు చెల్లిస్తున్నారా?",
                        "Assamese": "Q4. এই এয়াৰটেল পোষ্টপেইড সংযোগৰ বিল আপুনি দিবনে?",
                        "Marathi": "Q4. तुम्ही या एअरटेल पोस्टपेड कनेक्शनचे बिल भरता का?",
                        "Oriya": "Q4. ଏହି ଏୟାରଟେଲ ପୋଷ୍ଟପେଡ୍ ସଂଯୋଗ ପାଇଁ ଆପଣ ବିଲ୍ ଦେବେ କି?",
                        "Bengali": "Q4.এয়ারটেলের এই পোস্টপেইড কানেকশনের জন্য আপনি কি বিল পরিশোধ করেন?",
                        "Tamil": "Q4. இந்த ஏர்டெல் போஸ்ட்பெய்டு இணைப்புக்கான கட்டணத்தைச் செலுத்துகிறீர்களா?",
                        "Hindi": "Q4. क्या आप इस एयरटेल पोस्टपेड कनेक्शन के बिल का पेमेंट करते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "stopLogic": {
                        "relatedAnswer": [
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 6
                },
                {
                    "id": "7",
                    "code": "Q5",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q5.  Do you receive SMS for your bill?",
                        "Malayalam": "Q5. ഈ എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് കണക്ഷന്റെ ബിൽ നിങ്ങൾ അടയ്ക്കുന്നുണ്ടോ?",
                        "Kannada": "Q5. ನಿಮ್ಮ ಬಿಲ್‌ಗಾಗಿ ನೀವು SMS ಸ್ವೀಕರಿಸುತ್ತೀರಾ?",
                        "Gujarati": "Q5. શું તમને તમારા બિલ માટે SMS મળે છે?",
                        "Telugu": "Q5. మీరు మీ బిల్లు కోసం SMS అందుకుంటున్నారా?",
                        "Assamese": "Q5.আপোনাৰ বিলৰ বাবে এছএমএছ লাভ কৰে নেকি?",
                        "Marathi": "Q5. तुम्हाला तुमच्या बिलासाठी एसएमएस मिळतात का?",
                        "Oriya": "Q5.ତୁମର ବିଲ୍ ପାଇଁ ଆପଣ SMS ଗ୍ରହଣ କରନ୍ତି କି?",
                        "Bengali": "Q5. আপনি কি আপনার বিলের জন্য এসএমএস পান?",
                        "Tamil": "Q5. உங்களின் பில்லுக்கு SMS பெறுகிறீர்களா?",
                        "Hindi": "Q5. क्या आपको अपने बिल के लिए एसएमएस प्राप्त होता है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 7
                },
                {
                    "id": "8",
                    "code": "Q61",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q6. Considering your recent experience with the following attributes, how likely are you to recommend Airtel Postpaid to your friends/family.",
                        "Malayalam": "Q6. ഇനിപ്പറയുന്ന ആട്രിബ്യൂട്ടുകളുമായുള്ള നിങ്ങളുടെ സമീപകാല അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, നിങ്ങളുടെ സുഹൃത്തുക്കൾക്ക്/കുടുംബത്തിന് എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്.",
                        "Kannada": "Q6. ಕೆಳಗಿನ ಗುಣಲಕ್ಷಣಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ನಿಮ್ಮ ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ನೀವು ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆಯಿದೆ.",
                        "Gujarati": "Q6. નીચેના લક્ષણો સાથેના તમારા તાજેતરના અનુભવને ધ્યાનમાં લેતા, તમે તમારા મિત્રો/કુટુંબને એરટેલ પોસ્ટપેડની ભલામણ કરવાની કેટલી સંભાવના છે.",
                        "Telugu": "Q6. కింది లక్షణాలతో మీ ఇటీవలి అనుభవాన్ని పరిశీలిస్తే, మీరు మీ స్నేహితులు/కుటుంబానికి Airtel పోస్ట్‌పెయిడ్‌ని ఎంతవరకు సిఫార్సు చేయవచ్చు.",
                        "Assamese": "Q6. তলত উল্লেখ কৰা বৈশিষ্ট্যসমূহৰ সৈতে আপোনাৰ শেহতীয়া অভিজ্ঞতাৰ কথা বিবেচনা কৰি আপুনি আপোনাৰ বন্ধু-বান্ধৱ/পৰিজনলৈ এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ আগবঢ়োৱাৰ সম্ভাৱনা কিমান।",
                        "Marathi": "Q6. खालील विशेषतांसह तुमचा अलीकडील अनुभव लक्षात घेता, तुम्ही तुमच्या मित्र/कुटुंबाला एअरटेल पोस्टपेडची सिफारिश करण्याची किती शक्यता आहे.",
                        "Oriya": "Q6. ନିମ୍ନଲିଖିତ ଗୁଣଗୁଡିକ ସହିତ ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ଆପଣ ଆପଣଙ୍କର ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ୍ ପୋଷ୍ଟପେଡ୍ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q6. নিম্নলিখিত বৈশিষ্ট্যগুলির সাথে আপনার সাম্প্রতিক অভিজ্ঞতা বিবেচনা করে, আপনি আপনার বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইডের সুপারিশ করার সম্ভাবনা কতটা।",
                        "Tamil": "Q6. பின்வரும் பண்புக்கூறுகளுடன் உங்களின் சமீபத்திய அனுபவத்தைக் கருத்தில் கொண்டு, உங்கள் நண்பர்கள்/குடும்பத்தினருக்கு Airtel Postpaidஐப் பரிந்துரைப்பது எவ்வளவு சாத்தியம்.",
                        "Hindi": "Q6. निम्नलिखित विशेषताओं के साथ आपके हाल के अनुभव को ध्यान में रखते हुए, आप अपने दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना रखते हैं।"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Timely intimation through SMS and app for bill amount and due date</span>",
                        "Malayalam": "<span style=\"color:orange;font-size:15pt;\">ബിൽ തുകയും അടയ്‌ക്കേണ്ട തീയതിയും സംബന്ധിച്ച് SMS വഴിയും ആപ്പിലൂടെയും സമയബന്ധിതമായ അറിയിപ്പ്</span>",
                        "Kannada": "<span style=\"color:orange;font-size:15pt;\">ಬಿಲ್ ಮೊತ್ತ ಮತ್ತು ಅಂತಿಮ ದಿನಾಂಕಕ್ಕಾಗಿ SMS ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್ ಮೂಲಕ ಸಮಯೋಚಿತ ಸೂಚನೆ</span>",
                        "Gujarati": "<span style=\"color:orange;font-size:15pt;\">બિલની રકમ અને નિયત તારીખ માટે SMS અને એપ્લિકેશન દ્વારા સમયસર માહિતી</span>",
                        "Telugu": "<span style=\"color:orange;font-size:15pt;\">బిల్లు మొత్తం మరియు గడువు తేదీ కోసం SMS మరియు యాప్ ద్వారా సకాలంలో సమాచారం</span>",
                        "Assamese": "<span style=\"color:orange;font-size:15pt;\">বিলৰ পৰিমাণ আৰু নিৰ্ধাৰিত তাৰিখৰ বাবে এছ এম এছ আৰু এপযোগে সময়মতে জাননী</span>",
                        "Marathi": "<span style=\"color:orange;font-size:15pt;\">बिलाची रक्कम आणि देय तारखेसाठी एसएमएस आणि अॅपद्वारे वेळेवर सूचना</span>",
                        "Oriya": "<span style=\"color:orange;font-size:15pt;\">ବିଲ୍ ରାଶି ଏବଂ ଧାର୍ଯ୍ୟ ତାରିଖ ପାଇଁ SMS ଏବଂ ଆପ୍ ମାଧ୍ୟମରେ ସମୟାନୁବର୍ତ୍ତୀ |</span>",
                        "Bengali": "<span style=\"color:orange;font-size:15pt;\">বিলের পরিমাণ ও নির্ধারিত তারিখের জন্য এসএমএস ও অ্যাপের মাধ্যমে যথাসময়ে অবহিতকরণ</span>",
                        "Tamil": "<span style=\"color:orange;font-size:15pt;\">பில் தொகை மற்றும் நிலுவைத் தேதிக்கான SMS மற்றும் ஆப் மூலம் சரியான நேரத்தில் தகவல்</span>",
                        "Hindi": "<span style=\"color:orange;font-size:15pt;\">बिल राशि और देय तिथि के लिए एसएमएस और ऐप के माध्यम से समय पर सूचना</span>"
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 8
                },
                {
                    "id": "9",
                    "code": "Q62",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q6. Considering your recent experience with the following attributes, how likely are you to recommend Airtel Postpaid to your friends/family.",
                        "Malayalam": "Q6. ഇനിപ്പറയുന്ന ആട്രിബ്യൂട്ടുകളുമായുള്ള നിങ്ങളുടെ സമീപകാല അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, നിങ്ങളുടെ സുഹൃത്തുക്കൾക്ക്/കുടുംബത്തിന് എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്.",
                        "Kannada": "Q6. ಕೆಳಗಿನ ಗುಣಲಕ್ಷಣಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ನಿಮ್ಮ ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ನೀವು ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆಯಿದೆ.",
                        "Gujarati": "Q6. નીચેના લક્ષણો સાથેના તમારા તાજેતરના અનુભવને ધ્યાનમાં લેતા, તમે તમારા મિત્રો/કુટુંબને એરટેલ પોસ્ટપેડની ભલામણ કરવાની કેટલી સંભાવના છે.",
                        "Telugu": "Q6. కింది లక్షణాలతో మీ ఇటీవలి అనుభవాన్ని పరిశీలిస్తే, మీరు మీ స్నేహితులు/కుటుంబానికి Airtel పోస్ట్‌పెయిడ్‌ని ఎంతవరకు సిఫార్సు చేయవచ్చు.",
                        "Assamese": "Q6. তলত উল্লেখ কৰা বৈশিষ্ট্যসমূহৰ সৈতে আপোনাৰ শেহতীয়া অভিজ্ঞতাৰ কথা বিবেচনা কৰি আপুনি আপোনাৰ বন্ধু-বান্ধৱ/পৰিজনলৈ এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ আগবঢ়োৱাৰ সম্ভাৱনা কিমান।",
                        "Marathi": "Q6. खालील विशेषतांसह तुमचा अलीकडील अनुभव लक्षात घेता, तुम्ही तुमच्या मित्र/कुटुंबाला एअरटेल पोस्टपेडची सिफारिश करण्याची किती शक्यता आहे.",
                        "Oriya": "Q6. ନିମ୍ନଲିଖିତ ଗୁଣଗୁଡିକ ସହିତ ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ଆପଣ ଆପଣଙ୍କର ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ୍ ପୋଷ୍ଟପେଡ୍ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q6. নিম্নলিখিত বৈশিষ্ট্যগুলির সাথে আপনার সাম্প্রতিক অভিজ্ঞতা বিবেচনা করে, আপনি আপনার বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইডের সুপারিশ করার সম্ভাবনা কতটা।",
                        "Tamil": "Q6. பின்வரும் பண்புக்கூறுகளுடன் உங்களின் சமீபத்திய அனுபவத்தைக் கருத்தில் கொண்டு, உங்கள் நண்பர்கள்/குடும்பத்தினருக்கு Airtel Postpaidஐப் பரிந்துரைப்பது எவ்வளவு சாத்தியம்.",
                        "Hindi": "Q6. निम्नलिखित विशेषताओं के साथ आपके हाल के अनुभव को ध्यान में रखते हुए, आप अपने दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना रखते हैं।"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Sufficient time between intimation date and due date</span>",
                        "Malayalam": "<span style=\"color:orange;font-size:15pt;\">അറിയിപ്പ് തീയതിക്കും അവസാന തീയതിക്കും ഇടയിൽ മതിയായ സമയം</span>",
                        "Kannada": "<span style=\"color:orange;font-size:15pt;\">ಮಾಹಿತಿ ದಿನಾಂಕ ಮತ್ತು ಅಂತಿಮ ದಿನಾಂಕದ ನಡುವೆ ಸಾಕಷ್ಟು ಸಮಯ</span>",
                        "Gujarati": "<span style=\"color:orange;font-size:15pt;\">સૂચના તારીખ અને નિયત તારીખ વચ્ચે પૂરતો સમય</span>",
                        "Telugu": "<span style=\"color:orange;font-size:15pt;\">సమాచార తేదీ మరియు గడువు తేదీ మధ్య తగినంత సమయం</span>",
                        "Assamese": "<span style=\"color:orange;font-size:15pt;\">জাননী দিয়া তাৰিখ আৰু নিৰ্ধাৰিত তাৰিখৰ মাজত যথেষ্ট সময় </span>",
                        "Marathi": "<span style=\"color:orange;font-size:15pt;\">सूचना तारीख आणि देय तारीख दरम्यान पुरेसा वेळ</span>",
                        "Oriya": "<span style=\"color:orange;font-size:15pt;\">ଅନ୍ତରଙ୍ଗ ତାରିଖ ଏବଂ ଧାର୍ଯ୍ୟ ତାରିଖ ମଧ୍ୟରେ ପର୍ଯ୍ୟାପ୍ତ ସମୟ |</span>",
                        "Bengali": "<span style=\"color:orange;font-size:15pt;\">আবেদনের তারিখ এবং নির্ধারিত তারিখের মধ্যে যথেষ্ট সময়</span>",
                        "Tamil": "<span style=\"color:orange;font-size:15pt;\">தகவல் தெரிவிக்கும் தேதிக்கும் கடைசி தேதிக்கும் இடையே போதுமான நேரம்</span>",
                        "Hindi": "<span style=\"color:orange;font-size:15pt;\">सूचना तिथि और नियत तिथि के बीच पर्याप्त समय</span>"
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 9
                },
                {
                    "id": "10",
                    "code": "Q63",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q6. Considering your recent experience with the following attributes, how likely are you to recommend Airtel Postpaid to your friends/family.",
                        "Malayalam": "Q6. ഇനിപ്പറയുന്ന ആട്രിബ്യൂട്ടുകളുമായുള്ള നിങ്ങളുടെ സമീപകാല അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, നിങ്ങളുടെ സുഹൃത്തുക്കൾക്ക്/കുടുംബത്തിന് എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്.",
                        "Kannada": "Q6. ಕೆಳಗಿನ ಗುಣಲಕ್ಷಣಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ನಿಮ್ಮ ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ನೀವು ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆಯಿದೆ.",
                        "Gujarati": "Q6. નીચેના લક્ષણો સાથેના તમારા તાજેતરના અનુભવને ધ્યાનમાં લેતા, તમે તમારા મિત્રો/કુટુંબને એરટેલ પોસ્ટપેડની ભલામણ કરવાની કેટલી સંભાવના છે.",
                        "Telugu": "Q6. కింది లక్షణాలతో మీ ఇటీవలి అనుభవాన్ని పరిశీలిస్తే, మీరు మీ స్నేహితులు/కుటుంబానికి Airtel పోస్ట్‌పెయిడ్‌ని ఎంతవరకు సిఫార్సు చేయవచ్చు.",
                        "Assamese": "Q6. তলত উল্লেখ কৰা বৈশিষ্ট্যসমূহৰ সৈতে আপোনাৰ শেহতীয়া অভিজ্ঞতাৰ কথা বিবেচনা কৰি আপুনি আপোনাৰ বন্ধু-বান্ধৱ/পৰিজনলৈ এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ আগবঢ়োৱাৰ সম্ভাৱনা কিমান।",
                        "Marathi": "Q6. खालील विशेषतांसह तुमचा अलीकडील अनुभव लक्षात घेता, तुम्ही तुमच्या मित्र/कुटुंबाला एअरटेल पोस्टपेडची सिफारिश करण्याची किती शक्यता आहे.",
                        "Oriya": "Q6. ନିମ୍ନଲିଖିତ ଗୁଣଗୁଡିକ ସହିତ ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ଆପଣ ଆପଣଙ୍କର ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ୍ ପୋଷ୍ଟପେଡ୍ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q6. নিম্নলিখিত বৈশিষ্ট্যগুলির সাথে আপনার সাম্প্রতিক অভিজ্ঞতা বিবেচনা করে, আপনি আপনার বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইডের সুপারিশ করার সম্ভাবনা কতটা।",
                        "Tamil": "Q6. பின்வரும் பண்புக்கூறுகளுடன் உங்களின் சமீபத்திய அனுபவத்தைக் கருத்தில் கொண்டு, உங்கள் நண்பர்கள்/குடும்பத்தினருக்கு Airtel Postpaidஐப் பரிந்துரைப்பது எவ்வளவு சாத்தியம்.",
                        "Hindi": "Q6. निम्नलिखित विशेषताओं के साथ आपके हाल के अनुभव को ध्यान में रखते हुए, आप अपने दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना रखते हैं।"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Accuracy of last bill in terms of the bill amount, charges and rentals etc.</span>",
                        "Malayalam": "<span style=\"color:orange;font-size:15pt;\">ബിൽ തുക, ചാർജുകൾ, വാടക തുടങ്ങിയവയുടെ കാര്യത്തിൽ അവസാന ബില്ലിന്റെ കൃത്യത.</span>",
                        "Kannada": "<span style=\"color:orange;font-size:15pt;\">ಬಿಲ್ ಮೊತ್ತ, ಶುಲ್ಕಗಳು ಮತ್ತು ಬಾಡಿಗೆಗಳು ಇತ್ಯಾದಿಗಳ ವಿಷಯದಲ್ಲಿ ಕೊನೆಯ ಬಿಲ್‌ನ ನಿಖರತೆ.</span>",
                        "Gujarati": "<span style=\"color:orange;font-size:15pt;\">બિલની રકમ, શુલ્ક અને ભાડા વગેરેના સંદર્ભમાં છેલ્લા બિલની ચોકસાઈ.</span>",
                        "Telugu": "<span style=\"color:orange;font-size:15pt;\">బిల్లు మొత్తం, ఛార్జీలు మరియు అద్దెలు మొదలైన వాటి పరంగా చివరి బిల్లు యొక్క ఖచ్చితత్వం.</span>",
                        "Assamese": "<span style=\"color:orange;font-size:15pt;\">বিলৰ পৰিমাণ, মাচুল আৰু ভাড়া আদিৰ ক্ষেত্ৰত শেষ বিলৰ সঠিকতা।</span>",
                        "Marathi": "<span style=\"color:orange;font-size:15pt;\">बिलाची रक्कम, शुल्क आणि भाडे इत्यादींच्या बाबतीत शेवटच्या बिलाची अचूकता.</span>",
                        "Oriya": "<span style=\"color:orange;font-size:15pt;\">ବିଲ୍ ପରିମାଣ, ଦେୟ ଏବଂ ଭଡା ଇତ୍ୟାଦି ଅନୁଯାୟୀ ଶେଷ ବିଲ୍ ର ସଠିକତା |</span>",
                        "Bengali": "<span style=\"color:orange;font-size:15pt;\">বিলের পরিমাণ, চার্জ ও ভাড়া ইত্যাদির নিরিখে সর্বশেষ বিলের সঠিকতা.</span>",
                        "Tamil": "<span style=\"color:orange;font-size:15pt;\">பில் தொகை, கட்டணங்கள் மற்றும் வாடகைகள் போன்றவற்றின் அடிப்படையில் கடைசி பில்லின் துல்லியம்.</span>",
                        "Hindi": "<span style=\"color:orange;font-size:15pt;\">बिल राशि, शुल्क और किराये आदि के संदर्भ में पिछले बिल की सटीकता।</span>"
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 10
                },
                {
                    "id": "11",
                    "code": "Q64",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q6. Considering your recent experience with the following attributes, how likely are you to recommend Airtel Postpaid to your friends/family.",
                        "Malayalam": "Q6. ഇനിപ്പറയുന്ന ആട്രിബ്യൂട്ടുകളുമായുള്ള നിങ്ങളുടെ സമീപകാല അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, നിങ്ങളുടെ സുഹൃത്തുക്കൾക്ക്/കുടുംബത്തിന് എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്.",
                        "Kannada": "Q6. ಕೆಳಗಿನ ಗುಣಲಕ್ಷಣಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ನಿಮ್ಮ ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ನೀವು ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆಯಿದೆ.",
                        "Gujarati": "Q6. નીચેના લક્ષણો સાથેના તમારા તાજેતરના અનુભવને ધ્યાનમાં લેતા, તમે તમારા મિત્રો/કુટુંબને એરટેલ પોસ્ટપેડની ભલામણ કરવાની કેટલી સંભાવના છે.",
                        "Telugu": "Q6. కింది లక్షణాలతో మీ ఇటీవలి అనుభవాన్ని పరిశీలిస్తే, మీరు మీ స్నేహితులు/కుటుంబానికి Airtel పోస్ట్‌పెయిడ్‌ని ఎంతవరకు సిఫార్సు చేయవచ్చు.",
                        "Assamese": "Q6. তলত উল্লেখ কৰা বৈশিষ্ট্যসমূহৰ সৈতে আপোনাৰ শেহতীয়া অভিজ্ঞতাৰ কথা বিবেচনা কৰি আপুনি আপোনাৰ বন্ধু-বান্ধৱ/পৰিজনলৈ এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ আগবঢ়োৱাৰ সম্ভাৱনা কিমান।",
                        "Marathi": "Q6. खालील विशेषतांसह तुमचा अलीकडील अनुभव लक्षात घेता, तुम्ही तुमच्या मित्र/कुटुंबाला एअरटेल पोस्टपेडची सिफारिश करण्याची किती शक्यता आहे.",
                        "Oriya": "Q6. ନିମ୍ନଲିଖିତ ଗୁଣଗୁଡିକ ସହିତ ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ଆପଣ ଆପଣଙ୍କର ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ୍ ପୋଷ୍ଟପେଡ୍ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q6. নিম্নলিখিত বৈশিষ্ট্যগুলির সাথে আপনার সাম্প্রতিক অভিজ্ঞতা বিবেচনা করে, আপনি আপনার বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইডের সুপারিশ করার সম্ভাবনা কতটা।",
                        "Tamil": "Q6. பின்வரும் பண்புக்கூறுகளுடன் உங்களின் சமீபத்திய அனுபவத்தைக் கருத்தில் கொண்டு, உங்கள் நண்பர்கள்/குடும்பத்தினருக்கு Airtel Postpaidஐப் பரிந்துரைப்பது எவ்வளவு சாத்தியம்.",
                        "Hindi": "Q6. निम्नलिखित विशेषताओं के साथ आपके हाल के अनुभव को ध्यान में रखते हुए, आप अपने दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना रखते हैं।"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Ease of understanding all the details in the Bill</span>",
                        "Malayalam": "<span style=\"color:orange;font-size:15pt;\">ബില്ലിലെ എല്ലാ വിശദാംശങ്ങളും എളുപ്പത്തിൽ മനസ്സിലാക്കാം</span>",
                        "Kannada": "<span style=\"color:orange;font-size:15pt;\">ಮಸೂದೆಯಲ್ಲಿನ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಸುಲಭ</span>",
                        "Gujarati": "<span style=\"color:orange;font-size:15pt;\">બિલની તમામ વિગતોને સમજવામાં સરળતા</span>",
                        "Telugu": "<span style=\"color:orange;font-size:15pt;\">బిల్లులోని అన్ని వివరాలను సులభంగా అర్థం చేసుకోవచ్చు</span>",
                        "Assamese": "<span style=\"color:orange;font-size:15pt;\">বিধেয়কখনত থকা সকলো তথ্য বুজিবলৈ সহজ</span>",
                        "Marathi": "<span style=\"color:orange;font-size:15pt;\">बिलिंग सर्व तपशील समजून घेणे सोपे</span>",
                        "Oriya": "<span style=\"color:orange;font-size:15pt;\">ବିଲରେ ଥିବା ସମସ୍ତ ବିବରଣୀ ବୁ understanding ିବାର ସହଜ |</span>",
                        "Bengali": "<span style=\"color:orange;font-size:15pt;\">বিলের সমস্ত খুঁটিনাটি বিষয় সহজে বোঝা যায়</span>",
                        "Tamil": "<span style=\"color:orange;font-size:15pt;\">மசோதாவில் உள்ள அனைத்து விவரங்களையும் புரிந்துகொள்வது எளிது</span>",
                        "Hindi": "<span style=\"color:orange;font-size:15pt;\">बिल के सभी विवरणों को समझने में आसानी</span>"
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 11
                },
                {
                    "id": "12",
                    "code": "Q7",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q7. Considering your recent <b>experience with Airtel Postpaid</b>, how likely are you to <b>recommend Airtel Postpaid</b> to friends/ family?",
                        "Malayalam": "Q7. എയർടെൽ പോസ്റ്റ്‌പെയ്ഡുമായുള്ള നിങ്ങളുടെ സമീപകാല അനുഭവം കണക്കിലെടുക്കുമ്പോൾ, സുഹൃത്തുക്കൾക്കും കുടുംബാംഗങ്ങൾക്കും എയർടെൽ പോസ്റ്റ്‌പെയ്ഡ് ശുപാർശ ചെയ്യാൻ നിങ്ങൾ എത്രത്തോളം സാധ്യതയുണ്ട്?",
                        "Kannada": "Q7. ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನೊಂದಿಗಿನ ನಿಮ್ಮ ಇತ್ತೀಚಿನ ಅನುಭವವನ್ನು ಪರಿಗಣಿಸಿ, ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಅನ್ನು ಸ್ನೇಹಿತರು/ಕುಟುಂಬಕ್ಕೆ ಶಿಫಾರಸು ಮಾಡುವ ಸಾಧ್ಯತೆ ಎಷ್ಟು?",
                        "Gujarati": "Q7. એરટેલ પોસ્ટપેડ સાથેના તમારા તાજેતરના અનુભવને ધ્યાનમાં લેતા, તમે મિત્રો/પરિવારને એરટેલ પોસ્ટપેડની ભલામણ કરો તેવી કેટલી શક્યતા છે?",
                        "Telugu": "Q7. Airtel పోస్ట్‌పెయిడ్‌తో మీ ఇటీవలి అనుభవాన్ని పరిశీలిస్తే, మీరు ఎయిర్‌టెల్ పోస్ట్‌పెయిడ్‌ని స్నేహితులు/కుటుంబ సభ్యులకు ఎంతవరకు సిఫార్సు చేయవచ్చు?",
                        "Assamese": "Q7. এয়াৰটেল পোষ্টপেইডৰ সৈতে আপোনাৰ শেহতীয়া অভিজ্ঞতা বিবেচনা কৰিলে, আপুনি বন্ধু/পৰিয়ালক এয়াৰটেল পোষ্টপেইডৰ পৰামৰ্শ দিয়াৰ সম্ভাৱনা কিমান?",
                        "Marathi": "Q7. एअरटेल पोस्टपेडचा तुमचा अलीकडील अनुभव लक्षात घेता, तुम्ही मित्र/परिवाराला एअरटेल पोस्टपेडची सिफारिश करण्याची कितपत शक्यता आहे?",
                        "Oriya": "Q7. ଏୟାରଟେଲ ପୋଷ୍ଟପେଡ୍ ସହିତ ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ଅଭିଜ୍ଞତାକୁ ବିଚାରକୁ ନେଇ, ଆପଣ ବନ୍ଧୁ / ପରିବାରକୁ ଏୟାରଟେଲ୍ ପୋଷ୍ଟପେଡ୍ ସୁପାରିଶ କରିବାର ସମ୍ଭାବନା କେତେ?",
                        "Bengali": "Q7. এয়ারটেল পোস্টপেইড নিয়ে আপনার সাম্প্রতিক অভিজ্ঞতার কথা বিবেচনা করে, আপনি বন্ধু / পরিবারের কাছে এয়ারটেল পোস্টপেইডের সুপারিশ করার সম্ভাবনা কতটা?",
                        "Tamil": "Q7. ஏர்டெல் போஸ்ட்பெய்டு உடனான உங்களின் சமீபத்திய அனுபவத்தை கருத்தில் கொண்டு, நண்பர்கள்/குடும்பத்தினருக்கு Airtel போஸ்ட்பெய்டை பரிந்துரைப்பது எவ்வளவு சாத்தியம்?",
                        "Hindi": "Q7. एयरटेल पोस्टपेड के साथ आपके हाल के अनुभव को ध्यान में रखते हुए, दोस्तों/परिवार को एयरटेल पोस्टपेड की सिफारिश करने की कितनी संभावना है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 12
                },
                {
                    "id": "13",
                    "code": "Q8",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q7. As compared to the other brands of Postpaid, how do you rate the overall performance of Airtel Postpaid?",
                        "Malayalam": "Q7. പോസ്റ്റ്പെയ്ഡിന്റെ മറ്റ് ബ്രാൻഡുകളുമായി താരതമ്യപ്പെടുത്തുമ്പോൾ, എയർടെൽ പോസ്റ്റ്പെയ്ഡിന്റെ മൊത്തത്തിലുള്ള പ്രകടനം നിങ്ങൾ എങ്ങനെയാണ് വിലയിരുത്തുന്നത്?",
                        "Kannada": "Q7. ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ಇತರ ಬ್ರ್ಯಾಂಡ್‌ಗಳಿಗೆ ಹೋಲಿಸಿದರೆ, ಏರ್‌ಟೆಲ್ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್‌ನ ಒಟ್ಟಾರೆ ಕಾರ್ಯಕ್ಷಮತೆಯನ್ನು ನೀವು ಹೇಗೆ ರೇಟ್ ಮಾಡುತ್ತೀರಿ?",
                        "Gujarati": "Q7. પોસ્ટપેડની અન્ય બ્રાન્ડ્સની તુલનામાં, તમે એરટેલ પોસ્ટપેડના એકંદર પ્રદર્શનને કેવી રીતે રેટ કરો છો?",
                        "Telugu": "Q7. పోస్ట్‌పెయిడ్ యొక్క ఇతర బ్రాండ్‌లతో పోలిస్తే, మీరు Airtel పోస్ట్‌పెయిడ్ యొక్క మొత్తం పనితీరును ఎలా రేట్ చేస్తారు?",
                        "Assamese": "Q7. পোষ্টপেইডৰ আন ব্ৰেণ্ডৰ তুলনাত আপুনি এয়াৰটেল পোষ্টপেইডৰ সামগ্ৰিক প্ৰদৰ্শন কেনেকৈ ৰেটিং দিয়ে?",
                        "Marathi": "Q7. पोस्टपेडच्या इतर ब्रँडच्या तुलनेत, तुम्ही एअरटेल पोस्टपेडच्या एकूण कामगिरीला कसे रेट करता?",
                        "Oriya": "Q7. ପୋଷ୍ଟପେଡର ଅନ୍ୟ ବ୍ରାଣ୍ଡ ତୁଳନାରେ, ଆପଣ ଏୟାରଟେଲ ପୋଷ୍ଟପେଡର ସାମଗ୍ରିକ କାର୍ଯ୍ୟଦକ୍ଷତାକୁ କିପରି ମୂଲ୍ୟାଙ୍କନ କରିବେ?",
                        "Bengali": "Q7. পোস্টপেইডের অন্যান্য ব্র্যান্ডের তুলনায়, আপনি এয়ারটেল পোস্টপেইডের সামগ্রিক কর্মক্ষমতাকে কীভাবে মূল্যায়ন করেন?",
                        "Tamil": "Q7. போஸ்ட்பெய்டின் மற்ற பிராண்டுகளுடன் ஒப்பிடுகையில், ஏர்டெல் போஸ்ட்பெய்டின் ஒட்டுமொத்த செயல்திறனை எப்படி மதிப்பிடுகிறீர்கள்?",
                        "Hindi": "Q7. पोस्टपेड के अन्य ब्रांडों की तुलना में, आप एयरटेल पोस्टपेड के समग्र प्रदर्शन को कैसे आंकते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Much Better,Better,About the same,Worse,Much Worse,Don’t Know/ Can’t say",
                        "Malayalam": "വളരെ നല്ലത്, നല്ലത്, അതേ കുറിച്ച്, മോശം, വളരെ മോശം, അറിയില്ല/ പറയാൻ കഴിയില്ല",
                        "Kannada": "ಹೆಚ್ಚು ಉತ್ತಮ, ಉತ್ತಮ, ಅದೇ ಬಗ್ಗೆ, ಕೆಟ್ಟದು, ಹೆಚ್ಚು ಕೆಟ್ಟದು, ಗೊತ್ತಿಲ್ಲ/ ಹೇಳಲಾರೆ",
                        "Gujarati": "વધુ સારું, વધુ સારું, સમાન વિશે, વધુ ખરાબ, ઘણું ખરાબ, ખબર નથી/ કહી શકતા નથી",
                        "Telugu": "మచ్ బెటర్, బెటర్, అదే గురించి, అధ్వాన్నంగా, చాలా చెత్తగా, తెలియదు/ చెప్పలేను",
                        "Assamese": "বহুত ভাল,ভাল,একেটাৰ বিষয়ে,বহুত,বহুত বেয়া,নাজানো/ ক’ব নোৱাৰো",
                        "Marathi": "बरेच चांगले, चांगले, त्याच बद्दल, वाईट, खूप वाईट, माहित नाही/ सांगू शकत नाही",
                        "Oriya": "ବହୁତ ଭଲ, ଭଲ, ସମାନ ବିଷୟରେ, ଖରାପ, ଅଧିକ ଖରାପ, ଜାଣ ନାହିଁ / କହିପାରିବ ନାହିଁ |",
                        "Bengali": "অনেক ভাল, ভাল, একই সম্পর্কে, আরও খারাপ, অনেক খারাপ, জানি না/ বলতে পারি না",
                        "Tamil": "மிகவும் சிறந்தது, சிறந்தது, அதே பற்றி, மோசமானது, மிகவும் மோசமானது, தெரியாது/ சொல்ல முடியாது",
                        "Hindi": "बहुत बेहतर, बेहतर, लगभग वैसा ही, बदतर, बहुत बुरा, पता नहीं/कह नहीं सकते"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 13
                },
                {
                    "id": "14",
                    "code": "Q9",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q8. Select your gender.",
                        "Malayalam": "Q8. നിങ്ങളുടെ ലിംഗഭേദം തിരഞ്ഞെടുക്കുക",
                        "Kannada": "Q8. ನಿಮ್ಮ ಲಿಂಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
                        "Gujarati": "Q8. તમારું લિંગ પસંદ કરો",
                        "Telugu": "Q8. మీ లింగాన్ని ఎంచుకోండి",
                        "Assamese": "Q8. আপোনাৰ লিংগ নিৰ্বাচন কৰক",
                        "Marathi": "Q8. तुमचे लिंग निवडा",
                        "Oriya": "Q8. ଆପଣଙ୍କ ଲିଙ୍ଗ ଚୟନ କରନ୍ତୁ।",
                        "Bengali": "Q8.আপনার লিঙ্গ নির্বাচন করুন",
                        "Tamil": "Q8. உங்கள் பாலினத்தை தேர்வுசெய்யவும்",
                        "Hindi": "Q8. अपना लिंग चुनें"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Male,Female",
                        "Malayalam": "ആൺ,സ്ത്രീ",
                        "Kannada": "ಪುರುಷ,ಹೆಣ್ಣು",
                        "Gujarati": "પુરૂષ,મહિલા",
                        "Telugu": "పురుషుడు,స్త్రీ",
                        "Assamese": "পুৰুষ,নাৰী",
                        "Marathi": "पुरुष,महिला",
                        "Oriya": "Male,Female",
                        "Bengali": "পুরুষ,স্ত্রীলোক",
                        "Tamil": "ஆண்,பெண்",
                        "Hindi": "पुरुष,महिला"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 14
                },
                {
                    "id": "15",
                    "code": "Q10",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q9. Your Age (in years)",
                        "Malayalam": "Q9. നിങ്ങളുടെ പ്രായം (വർഷങ്ങളിൽ)",
                        "Kannada": "Q9. ನಿಮ್ಮ ವಯಸ್ಸು (ವರ್ಷಗಳಲ್ಲಿ)",
                        "Gujarati": "Q9. તમારી ઉંમર (વર્ષમાં)",
                        "Telugu": "Q9. మీ వయస్సు (సంవత్సరాల్లో)",
                        "Assamese": "Q9. আপোনাৰ বয়স (বছৰত)",
                        "Marathi": "Q9. तुमचे वय (वर्षांमध्ये)",
                        "Oriya": "Q9। ଆପଣଙ୍କ ବୟସ (ବର୍ଷଗୁଡିକରେ)",
                        "Bengali": "Q9. আপনার বয়স (বছরে)",
                        "Tamil": "Q9. உங்கள் வயது (ஆண்டுகளில்)",
                        "Hindi": "Q9. आपकी आयु (वर्षों में)"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 15,
                    "NumberRange": [
                        18,
                        99, "Please provide a range between 100000 and 200000"
                    ]
                },
                {
                    "id": "16",
                    "code": "Q11",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q10. Do you use any other mobile connection for your personal use?",
                        "Malayalam": "Q10. നിങ്ങളുടെ സ്വകാര്യ ഉപയോഗത്തിനായി മറ്റേതെങ്കിലും മൊബൈൽ കണക്ഷൻ ഉപയോഗിക്കുന്നുണ്ടോ?",
                        "Kannada": "Q10. ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಬಳಕೆಗಾಗಿ ನೀವು ಬೇರೆ ಯಾವುದಾದರೂ ಮೊಬೈಲ್ ಸಂಪರ್ಕವನ್ನು ಬಳಸುತ್ತೀರಾ?",
                        "Gujarati": "Q10. શું તમે તમારા અંગત ઉપયોગ માટે કોઈ અન્ય મોબાઈલ કનેક્શનનો ઉપયોગ કરો છો?",
                        "Telugu": "Q10. మీ వ్యక్తిగత ఉపయోగం కొరకు మీరు మరేదైనా మొబైల్ కనెక్షన్ ఉపయోగిస్తున్నారా?",
                        "Assamese": "Q 10আপুনি আপোনাৰ ব্যক্তিগত ব্যৱহাৰৰ বাবে আন কোনো মোবাইল সংযোগ ব্যৱহাৰ কৰে নেকি?",
                        "Marathi": "Q10. तुम्ही तुमच्या वैयक्तिक वापरासाठी इतर कोणतेही मोबाइल कनेक्शन वापरता का?",
                        "Oriya": "Q10। ଆପଣ ଆପଣଙ୍କ ବ୍ୟକ୍ତିଗତ ବ୍ୟବହାର ପାଇଁ ଅନ୍ୟ କୌଣସି ମୋବାଇଲ୍ ସଂଯୋଜନା ବ୍ୟବହାର କରନ୍ତି କି?",
                        "Bengali": "Q10. আপনি কি আপনার ব্যক্তিগত ব্যবহারের জন্য অন্য কোনও মোবাইল সংযোগ ব্যবহার করেন?",
                        "Tamil": "Q10. உங்கள் தனிப்பட்ட பயன்பாட்டிற்கு வேறு ஏதேனும் மொபைல் இணைப்பைப் பயன்படுத்துகிறீர்களா?",
                        "Hindi": "Q10. क्या आप अपने व्यक्तिगत उपयोग के लिए किसी अन्य मोबाइल कनेक्शन का उपयोग करते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 16
                },
                {
                    "id": "17",
                    "code": "Q12",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q11. Which Mobile Operator do you use for your other connection?",
                        "Malayalam": "Q11. നിങ്ങളുടെ മറ്റ് കണക്ഷനായി ഏത് മൊബൈൽ ഓപ്പറേറ്ററാണ് നിങ്ങൾ ഉപയോഗിക്കുന്നത്?",
                        "Kannada": "Q11. ನಿಮ್ಮ ಇತರ ಸಂಪರ್ಕಕ್ಕಾಗಿ ನೀವು ಯಾವ ಮೊಬೈಲ್ ಆಪರೇಟರ್ ಅನ್ನು ಬಳಸುತ್ತೀರಿ?",
                        "Gujarati": "Q11. તમારા અન્ય કનેક્શન માટે તમે કયા મોબાઈલ ઓપરેટરનો ઉપયોગ કરો છો?\n",
                        "Telugu": "Q11. మీ ఇతర కనెక్షన్ కొరకు మీరు ఏ మొబైల్ ఆపరేటర్ ని ఉపయోగిస్తారు?",
                        "Assamese": "Q11আপুনি আপোনাৰ আন সংযোগৰ বাবে কোনটো ম'বাইল অপাৰেটৰ ব্যৱহাৰ কৰে?",
                        "Marathi": "Q 11. आपण आपल्या इतर कनेक्शनसाठी कोणता मोबाइल ऑपरेटर वापरता?",
                        "Oriya": "Q11। ଆପଣଙ୍କ ଅନ୍ୟ ସଂଯୋଜନା ପାଇଁ ଆପଣ କେଉଁ ମୋବାଇଲ୍ ଅପରେଟର ବ୍ୟବହାର କରନ୍ତି?",
                        "Bengali": "Q11. আপনি আপনার অন্যান্য সংযোগের জন্য কোন মোবাইল অপারেটর ব্যবহার করেন?",
                        "Tamil": "Q11. உங்கள் பிற இணைப்புக்கு எந்த மொபைல் ஆபரேட்டரைப் பயன்படுத்துகிறீர்கள்?",
                        "Hindi": "Q11. आप अपने अन्य कनेक्शन के लिए किस मोबाइल ऑपरेटर का उपयोग करते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Airtel,VI,JIO,BSNL",
                        "Malayalam": "എയർടെൽ,വോഡഫോൺ ഐഡിയ,ജിയോ,ബിഎസ്എൻഎൽ",
                        "Kannada": "ಏರ್ಟೆಲ್,ವೊಡಾಫೋನ್ ಐಡಿಯಾ,ಜಿಯೋ,ಬಿಎಸ್ಎನ್ಎಲ್",
                        "Gujarati": "એરટેલ,વોડાફોન આઈડિયા,જિયો,બી.એસ.એન.એલ.",
                        "Telugu": "ఎయిర్టెల్,వొడాఫోన్ ఐడియా,జియో,బీఎస్ఎన్ఎల్",
                        "Assamese": "এয়াৰটেল,ভোডাফোন আইডিয়া,জিঅ',বিএছএনএল",
                        "Marathi": "एअरटेल,व्होडाफोन आयडिया,जिओ,बीएसएनएल",
                        "Oriya": "ଏୟାରଟେଲ,ଭୋଡାଫୋନ୍ ଆଇଡିଆ,ଜିଓ,ବିଏସଏନଏଲ",
                        "Bengali": "এয়ারটেল,ভোডাফোন আইডিয়া,জিও,বিএসএনএল",
                        "Tamil": "ஏர்டெல்,வோடபோன் ஐடியா,ஜியோ,பிஎஸ்என்எல்",
                        "Hindi": "एयरटेल,वोडाफोन आइडिया,जियो,बीएसएनएल"
                    },
                    "displayLogic": {
                        "relatedId": 16,
                        "relatedQuestionSequence": 16,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 17
                },
                {
                    "id": "18",
                    "code": "Q12.1",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q11.1 Which Mobile Operator do you use as your Primary Connection?",
                        "Malayalam": "Q11.1 നിങ്ങളുടെ പ്രാഥമിക കണക്ഷനായി ഏത് മൊബൈൽ ഓപ്പറേറ്ററാണ് നിങ്ങൾ ഉപയോഗിക്കുന്നത്?",
                        "Kannada": "Q11.1. ನಿಮ್ಮ ಪ್ರಾಥಮಿಕ ಸಂಪರ್ಕವಾಗಿ ನೀವು ಯಾವ ಮೊಬೈಲ್ ಆಪರೇಟರ್ ಅನ್ನು ಬಳಸುತ್ತೀರಿ?",
                        "Gujarati": "Q11.1. તમારા પ્રાથમિક કનેક્શન તરીકે તમે કયા મોબાઈલ ઓપરેટરનો ઉપયોગ કરો છો?\n",
                        "Telugu": "Q11.1 మీ ప్రైమరీ కనెక్షన్ గా మీరు ఏ మొబైల్ ఆపరేటర్ ని ఉపయోగిస్తారు?",
                        "Assamese": "Q11.1 আপুনি আপোনাৰ প্ৰাথমিক সংযোগ হিচাপে কোনটো ম'বাইল অপাৰেটৰ ব্যৱহাৰ কৰে?",
                        "Marathi": "Q 11.1 आपण आपले प्राथमिक कनेक्शन म्हणून कोणता मोबाइल ऑपरेटर वापरता?",
                        "Oriya": "Q11.1 ଆପଣଙ୍କ ପ୍ରାଥମିକ ସଂଯୋଜନା ଭାବରେ ଆପଣ କେଉଁ ମୋବାଇଲ୍ ଅପରେଟର ବ୍ୟବହାର କରନ୍ତି?",
                        "Bengali": "Q11.1 আপনি কোন মোবাইল অপারেটরকে আপনার প্রাথমিক সংযোগ হিসাবে ব্যবহার করেন?",
                        "Tamil": "Q11.1 உங்கள் முதன்மை இணைப்பாக எந்த மொபைல் ஆபரேட்டரைப் பயன்படுத்துகிறீர்கள்?",
                        "Hindi": "Q11.1. आप अपने प्राथमिक कनेक्शन के रूप में किस मोबाइल ऑपरेटर का उपयोग करते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Airtel,VI,JIO,BSNL",
                        "Malayalam": "എയർടെൽ,വോഡഫോൺ ഐഡിയ,ജിയോ,ബിഎസ്എൻഎൽ",
                        "Kannada": "ಏರ್ಟೆಲ್,ವೊಡಾಫೋನ್ ಐಡಿಯಾ,ಜಿಯೋ,ಬಿಎಸ್ಎನ್ಎಲ್",
                        "Gujarati": "એરટેલ,વોડાફોન આઈડિયા,જિયો,બી.એસ.એન.એલ.",
                        "Telugu": "ఎయిర్టెల్,వొడాఫోన్ ఐడియా,జియో,బీఎస్ఎన్ఎల్",
                        "Assamese": "এয়াৰটেল,ভোডাফোন আইডিয়া,জিঅ',বিএছএনএল",
                        "Marathi": "एयरटेल,वोडाफोन आइडिया,जिओ,बीएसएनएल",
                        "Oriya": "ଏୟାରଟେଲ,ଭୋଡାଫୋନ୍ ଆଇଡିଆ,ଜିଓ,ବିଏସଏନଏଲ",
                        "Bengali": "এয়ারটেল,ভোডাফোন আইডিয়া,জিও,বিএসএনএল",
                        "Tamil": "ஏர்டெல்,வோடபோன் ஐடியா,ஜியோ,பிஎஸ்என்எல்",
                        "Hindi": "एयरटेल,वोडाफोन आइडिया,जियो,बीएसएनएल"
                    },
                    "displayLogic": {
                        "relatedId": 16,
                        "relatedQuestionSequence": 16,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 18
                },
                {
                    "id": "19",
                    "code": "Q13",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q12. Is it a PREPAID/ POSTPAID mobile connection?",
                        "Malayalam": "Q12. നിങ്ങളുടെ മറ്റൊരു കണക്ഷൻ പ്രീപെയ്ഡ് അല്ലെങ്കിൽ പോസ്റ്റ്പെയ്ഡ് കണക്ഷനാണോ?",
                        "Kannada": "Q12. ನಿಮ್ಮ ಇನ್ನೊಂದು ಸಂಪರ್ಕವು ಪ್ರಿಪೇಯ್ಡ್ ಅಥವಾ ಪೋಸ್ಟ್‌ಪೇಯ್ಡ್ ಸಂಪರ್ಕವೇ?",
                        "Gujarati": "Q12. શું તે પ્રીપેઇડ/પોસ્ટપેઇડ મોબાઇલ કનેક્શન છે?",
                        "Telugu": "Q12. ఇది ప్రీపెయిడ్/పోస్ట్ పెయిడ్ మొబైల్ కనెక్షన్ కాదా?",
                        "Assamese": "Q 12. এইটো এটা প্ৰিপেইড/ পোষ্টপেইড ম'বাইল সংযোগ নেকি?",
                        "Marathi": "Q12. हे प्रीपेड / पोस्टपेड मोबाइल कनेक्शन आहे का?",
                        "Oriya": "Q12। ଏହା ଏକ ପ୍ରିପେଡ୍ / ପୋଷ୍ଟପେଡ୍ ମୋବାଇଲ୍ ସଂଯୋଜନା କି?",
                        "Bengali": "Q12. এটি কি প্রিপেইড / পোস্টপেইড মোবাইল সংযোগ?",
                        "Tamil": "Q12. இது ப்ரீபெய்ட் / போஸ்ட்பெய்ட் மொபைல் இணைப்பா?",
                        "Hindi": "Q12. क्या आपका अन्य कनेक्शन प्रीपेड या पोस्टपेड कनेक्शन है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Prepaid,Postpaid",
                        "Malayalam": "പ്രീപെയ്ഡ്,പോസ്റ്റ്പെയ്ഡ്",
                        "Kannada": "ಪ್ರಿಪೇಯ್ಡ್,ಪೋಸ್ಟ್ ಪೇಯ್ಡ್",
                        "Gujarati": "પ્રીપેડ,પોસ્ટપેડ",
                        "Telugu": "ప్రీపెయిడ్,పోస్ట్ పెయిడ్",
                        "Assamese": "প্ৰিপেইড,পোষ্টপেইড",
                        "Marathi": "प्रीपेड,पोस्टपेड",
                        "Oriya": "ପ୍ରିପେଡ୍,ପୋଷ୍ଟପେଡ୍",
                        "Bengali": "প্রিপেইড,পোস্টপেইড",
                        "Tamil": "ப்ரீபெய்ட்,போஸ்ட்பெய்ட்",
                        "Hindi": "प्रीपेड,पोस्टपेड"
                    },
                    "displayLogic": {
                        "relatedId": 16,
                        "relatedQuestionSequence": 16,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 19
                },
                {
                    "id": "20",
                    "code": "Q14",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q13. Do you have broadband connection at home?",
                        "Malayalam": "Q13. നിങ്ങളുടെ വീട്ടിൽ ബ്രോഡ് ബാൻഡ് കണക്ഷൻ ഉണ്ടോ?",
                        "Kannada": "Q13. ನೀವು ಮನೆಯಲ್ಲಿ ಬ್ರಾಡ್ ಬ್ಯಾಂಡ್ ಸಂಪರ್ಕವನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
                        "Gujarati": "Q13. શું તમારી પાસે ઘરે બ્રોડબેન્ડ જોડાણ છે?",
                        "Telugu": "Q13. మీ ఇంట్లో బ్రాడ్ బ్యాండ్ కనెక్షన్ ఉందా?",
                        "Assamese": "Q13. আপোনাৰ ঘৰত ব্ৰডবেণ্ড সংযোগ আছে নেকি?",
                        "Marathi": "Q13. तुमच्या घरी ब्रॉडबँड कनेक्शन आहे का?",
                        "Oriya": "Q13। ଆପଣଙ୍କ ଘରେ ବ୍ରଡବ୍ୟାଣ୍ଡ ସଂଯୋଗ ଅଛି କି?",
                        "Bengali": "Q13. আপনার বাড়িতে কি ব্রডব্যান্ড সংযোগ আছে?",
                        "Tamil": "Q13. உங்கள் வீட்டில் பிராட்பேண்ட் இணைப்பு உள்ளதா?",
                        "Hindi": "Q13. क्या आपके घर में ब्रॉडबैंड कनेक्शन है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "displayLogic": {
                        "relatedId": 16,
                        "relatedQuestionSequence": 16,
                        "relatedAnswer": [
                            "Yes",
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 20
                },
                {
                    "id": "21",
                    "code": "Q15",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q14. Please tell me the brand/company of broadband connection which you have at home?",
                        "Malayalam": "Q14. നിങ്ങൾക്ക് വീട്ടിലുള്ള ബ്രോഡ് ബാൻഡ് കണക്ഷന്റെ ബ്രാൻഡ് / കമ്പനി എന്നോട് പറയുക?",
                        "Kannada": "Q14. ನೀವು ಮನೆಯಲ್ಲಿ ಹೊಂದಿರುವ ಬ್ರಾಡ್ ಬ್ಯಾಂಡ್ ಸಂಪರ್ಕದ ಬ್ರಾಂಡ್ / ಕಂಪನಿಯನ್ನು ದಯವಿಟ್ಟು ನನಗೆ ತಿಳಿಸಿ?",
                        "Gujarati": "Q14. મહેરબાની કરીને મને બ્રોડબેન્ડ જોડાણની બ્રાન્ડ/કંપની જણાવો કે જે તમારી પાસે ઘરમાં છે?",
                        "Telugu": "Q14. మీ ఇంట్లో ఉన్న బ్రాడ్ బ్యాండ్ కనెక్షన్ యొక్క బ్రాండ్/కంపెనీని దయచేసి నాకు చెప్పండి?",
                        "Assamese": "Q14. অনুগ্ৰহ কৰি আপোনাৰ ঘৰত থকা ব্ৰডবেণ্ড সংযোগৰ ব্ৰেণ্ড/কোম্পানীটো মোক কওঁক?",
                        "Marathi": "Q14. कृपया मला सांगा की आपल्या घरी ब्रॉडबँड कनेक्शनचा ब्रँड / कंपनी आहे?",
                        "Oriya": "Q14। ଦୟାକରି ମୋତେ ବ୍ରଡବ୍ୟାଣ୍ଡ ସଂଯୋଗର ବ୍ରାଣ୍ଡ / କମ୍ପାନୀକୁ କୁହନ୍ତୁ ଯାହା ଆପଣଙ୍କ ଘରେ ଅଛି?",
                        "Bengali": "Q14. দয়া করে আমাকে বলুন যে আপনার বাড়িতে ব্রডব্যান্ড সংযোগের ব্র্যান্ড / সংস্থা রয়েছে?",
                        "Tamil": "Q14. உங்கள் வீட்டில் இருக்கும் பிராட்பேண்ட் இணைப்பின் பிராண்ட் / நிறுவனத்தை என்னிடம் சொல்லுங்கள்?",
                        "Hindi": "Q14. कृपया मुझे आपके घर पर मौजूद ब्रॉडबैंड कनेक्शन का ब्रांड/कंपनी बताएं? "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Airtel,DEN,MTNL,BSNL,Hathway,Tata,Jio Giga Fiber,SITI,MTS,Excitel,Tikona,Nxtra,ACT Fibernet,Spectranet,YouBroadband,Gigatel,Triple Play,HCFL/Connect,Fastway,GTPL,Connect,IOS Broadband,Sikka,Alliance,Other : (Please Specify)",
                        "Malayalam": "എയർടെൽ,DEN,എം.ടി.എൻ.എൽ,ബി.എസ്.എൻ.എൽ,ഹാത്ത്വേ,ടാറ്റ,ജിയോ ഗിഗാ ഫൈബർ,എസ്.ഐ.ടി.ഐ,എം.ടി.എസ്,Excitel,ടിക്കോണ,Nxtra,ACT ഫൈബർനെറ്റ്,സ്പെക്ട്രാനെറ്റ്,YouBroadband,ഗിഗാറ്റെൽ,ട്രിപ്പിൾ പ്ലേ,HCFL/കണക്ട്,ഫാസ്റ്റ്വേ,GTPL,ബന്ധിപ്പിക്കുക,IOS ബ്രോഡ്ബാൻഡ്,സിക്ക,സഖ്യം,Other : (Please Specify)",
                        "Kannada": "ಏರ್ಟೆಲ್,DEN,ಎಂ.ಟಿ.ಎನ್.ಎಲ್,ಬಿ.ಎಸ್.ಎನ್.ಎಲ್,ಹಾಥ್ವೇ,ಟಾಟಾ,ಜಿಯೋ ಗಿಗಾ ಫೈಬರ್,SITI,ಎಂಟಿಎಸ್,ಎಕ್ಸಿಟೆಲ್,ಟಿಕೋನಾ,Nxtra,ACT ಫೈಬರ್ನೆಟ್,ಸ್ಪೆಕ್ಟ್ರಾನೆಟ್,YouBroadband,ಗಿಗಾಟೆಲ್,ಟ್ರಿಪಲ್ ಪ್ಲೇ,HCFL/ಸಂಪರ್ಕ,ವೇಗದ ಮಾರ್ಗ,GTPL,ಸಂಪರ್ಕಿಸಿ,IOS ಬ್ರಾಡ್‌ಬ್ಯಾಂಡ್,ಸಿಕ್ಕಾ,ಮೈತ್ರಿ,Other : (Please Specify)",
                        "Gujarati": "એરટેલ,ડેન,એમટીએનએલ,બીએસએનએલ,હેથવે,ટાટા,જિયો ગીગા ફાઈબર,SITI,MTS,એક્સાઇટેલ,ટીકોના,Nxtra,ACT ફાઇબરનેટ,સ્પેક્ટ્રાનેટ,YouBroadband,ગીગાટેલ,ટ્રિપલ પ્લે,એચસીએફએલ/કનેક્ટ,ફાસ્ટવે,જીટીપીએલ,જોડાવા,આઇઓએસ બ્રોડબેન્ડ,સિક્કા,જોડાણ,Other : (Please Specify)",
                        "Telugu": "ఎయిర్‌టెల్,డిఇఎన్,యంటిఎన్‌ఎల్,బిఎస్‌ఎన్‌ఎల్,హాత్‌వే,టాటా,జియో గిగాఫైబర్,సిటి,యంటియస్,ఎక్సైటెల్,టికోనా,నెక్స్ ట్రా,యాక్ట్ ఫైబర్‌నెట్,స్పెక్ట్రానెట్,యూబ్రాడ్‌బ్యాండ్,జిగాటెల్,ట్రిపుల్ ప్లే,హెచ్‌సిఎఫ్‌ఎల్/కనెక్ట్,ఫాస్ట్‌వే,జిటిపిఎల్,కనెక్ట్,ఐఒయస్ బ్రాడ్‌బ్యాండ్,సిక్కా,ఎలియన్స్,Other : (Please Specify)",
                        "Assamese": "এয়াৰটেল,ডিইএন,এমটিএনএল,বি এছ এন এল,হাথৱে,টাটা,জিঅ' গিগা ফাইবাৰ,চিটিআই,এম টি এছ,এক্সাইটেল,টিকোনা,Nxtra,এচিটি ফাইবাৰনেট,স্পেকট্ৰনেট,ইউব্ৰডবেণ্ড,গিগাটেল,ট্ৰিপল প্লে,এইচচিএফএল/কানেক্ট কৰক,ফাষ্টৱে,জিটিপিএল,সংযুক্ত কৰক,আই অ’ এছ ব্ৰডবেণ্ড,ছিক্কা,মিত্ৰতা,Other : (Please Specify)",
                        "Marathi": "एअरटेल,डेन,एमटीएनएल,बीएसएनएल,हॅथवे,टाटा,जिओ गिगा फायबर,सिटी,एमटीएस,एक्साइटेल,तिकोना,Nxtra,एसीटी फाइबरनेट,स्पेक्ट्रानेट,यू  ब्रॉडबँड,गिगाटेल,ट्रिपल प्ले,एचसीएफएल/कनेक्ट,फास्टवे,जीटीपीएल,कनेक्ट,iOS ब्रॉडबँड,सिक्का,अलायन्स,Other : (Please Specify)",
                        "Oriya": "ଏୟାରଟେଲ,ଡେନ,ଏମଟିଏନଏଲ,ବିଏସଏନଏଲ,ହାଥୱେ,ଟାଟା,ଜିଓ ଗିଗାଫାଇବର,ଏସଆଇଟିଆଇ,ଏମଟିଏସ,ଏକଶିଟେଲ,ଟିକୋନା,ନେକ୍ସଟ୍ରା,ଏସିଟି ଫାଇବାର ନେଟ,ସ୍ପେକଟ୍ରାନେଟ,ୟୁ ବ୍ରଡବାଣ୍ଡ,ଗିଗାଟେଲ,ଟ୍ରିପୁଲ ପ୍ଲେ,ଏଚସିଏଫଏଲ /କନେକ୍ଟ,ଫାଷ୍ଟୱେ,ଜିଟିପିଏଲ,କନେକ୍ଟ,ଆଇଓଏସ ବ୍ରଡବାଣ୍ଡ,ସିକା,ଆଲିଆନ୍ସ,Other : (Please Specify)",
                        "Bengali": "এয়ারটেল,ডেইন,এমটিএনএল,বিএসএনএল,হ্যাথওয়ে,টাটা,জিও গিগা ফাইবার,সিআইটিআই,এমটিএস,উদ্দীপক,তিকোনা,এনএক্সট্রা,এসিটি ফাইবারনেট,বর্ণালী,ত্বং বিদ্বান্বৃদ্ধং বিদ্বান্,গিগাটেল,ট্রিপল প্লে,এইচসিএফএল/কানেক্ট,দ্রুতগামী পথ,জিটিপিএল,সংযুক্ত করা,আইওএস ব্রডব্যান্ড,সিক্কা,মৈত্রী,Other : (Please Specify)",
                        "Tamil": "ஏர்டெல்,தீன்,எம்டிஎன்எல்,பிஎஸ்என்எல்,இடைவழி,டாட்டா,ஜியோ ஜிகா ஃபைபர்,எஸ்ஐடிஐ,எம்டிஎஸ்,தூண்டு,திக்கோனா,நியூட்ரா,ஏசிடி பைபர்னெட்,நிறமாலை,நீங்கள் பிராட்பேண்ட்,கிகாடெல்,டிரிபிள் பிளே,எச். சி. எப். எல். / இணைப்பு,விரைவு,ஜிடிபிஎல்,இணை,ஐஓஎஸ் பிராட்பேண்ட்,சிக்கா,கூட்டணி,Other : (Please Specify)",
                        "Hindi": "एयरटेल,डेन,एमटीएनएल,बीएसएनएल,हैथवे,टाटा,जियो गीगाफाइबर,सिटि,एमटीएस,एक्साइटेल,तिकोना,नेक्स्ट्रा,एसीटी फाइबरनेट,स्पेक्ट्रानेट,यूब्रॉडबैंड,गिगाटेल,ट्रिपल प्ले,एचसीएफएल / कनेक्ट,फ़ास्टवे,जीटीपीएल,कनेक्ट,iOS ब्रॉडबैंड,सिक्का,अलायंस,Other : (Please Specify)"
                    },
                    "displayLogic": {
                        "relatedId": 20,
                        "relatedQuestionSequence": 20,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 21
                },
                {
                    "id": "22",
                    "code": "Q16",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q15. Do you have DTH connection at home?",
                        "Malayalam": "Q15. വീട്ടിൽ ഡിടിഎച്ച് കണക്ഷൻ ഉണ്ടോ?",
                        "Kannada": "Q15. ನೀವು ಮನೆಯಲ್ಲಿ ಡಿಟಿಎಚ್ ಸಂಪರ್ಕವನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
                        "Gujarati": "Q15. શું તમારા ઘરે ડીટીએચ કનેક્શન છે?",
                        "Telugu": "Q15. ఇంట్లో డీటీహెచ్ కనెక్షన్ ఉందా?",
                        "Assamese": "Q15. আপোনাৰ ঘৰত ডিটিএইচ সংযোগ আছে নেকি?",
                        "Marathi": "Q15. तुमच्या घरी डीटीएच कनेक्शन आहे का?",
                        "Oriya": "Q15। ଆପଣଙ୍କ ଘରେ ଡିଟିଏଚ୍ ସଂଯୋଗ ଅଛି କି?",
                        "Bengali": "Q15. আপনার বাড়িতে কি ডিটিএইচ সংযোগ আছে?",
                        "Tamil": "Q15. உங்கள் வீட்டில் டிடிஎச் இணைப்பு உள்ளதா?",
                        "Hindi": "Q15. क्या आपके घर में डीटीएच कनेक्शन है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "displayLogic": {
                        "relatedId": 20,
                        "relatedQuestionSequence": 20,
                        "relatedAnswer": [
                            "Yes",
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 22
                },
                {
                    "id": "23",
                    "code": "Q17",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q16. Please tell me the name of DTH/ Cable TV connection which you have at home?",
                        "Malayalam": "Q16. നിങ്ങളുടെ വീട്ടിൽ ഉള്ള DTH/ കേബിൾ ടിവി കണക്ഷന്റെ പേര് പറയാമോ? ",
                        "Kannada": "Q16. ದಯವಿಟ್ಟು ನೀವು ಮನೆಯಲ್ಲಿ ಹೊಂದಿರುವ ಡಿಟಿಎಚ್ / ಕೇಬಲ್ ಟಿವಿ ಸಂಪರ್ಕದ ಹೆಸರನ್ನು ಹೇಳಿ?",
                        "Gujarati": "Q16. કૃપા કરીને મને ડીટીએચ/કેબલ ટીવી કનેક્શનનું નામ જણાવો, જે તમારા ઘરે છે?",
                        "Telugu": "Q16. మీ ఇంట్లో ఉన్న డిటిహెచ్/కేబుల్ టివి కనెక్షన్ పేరు చెప్పండి?",
                        "Assamese": "Q16. অনুগ্ৰহ কৰি আপোনাৰ ঘৰত থকা ডিটিএইচ/ কেবল টিভি সংযোগৰ নাম মোক কওঁক?",
                        "Marathi": "Q16. तुमच्या घरी असलेल्या डीटीएच/ केबल टीव्ही कनेक्शनचे नाव सांगा?",
                        "Oriya": "Q16। ଦୟାକରି ମୋତେ ଡିଟିଏଚ୍ / କେବୁଲ୍ ଟିଭି ସଂଯୋଗର ନାମ କୁହନ୍ତୁ ଯାହା ଆପଣଙ୍କ ଘରେ ଅଛି?",
                        "Bengali": "Q16. দয়া করে আমাকে ডিটিএইচ / কেবল টিভি সংযোগের নাম বলুন যা আপনার বাড়িতে রয়েছে?",
                        "Tamil": "Q16. உங்கள் வீட்டில் இருக்கும் டிடிஎச்/ கேபிள் டிவி இணைப்பின் பெயரைச் சொல்லுங்கள்?",
                        "Hindi": "Q16. कृपया मुझे आपके घर पर मौजूद डीटीएच/केबल टीवी कनेक्शन का नाम बताएं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Tata Sky,Dish TV,Airtel Digital TV,Videocon D2H,Independent TV (Reliance Digital / Big TV),Sun Direct,DD Free Dish,Asianet,DEN,Hathway,SITI Cable,Other : (Please Specify)",
                        "Malayalam": "ടാറ്റ സ്കൈ,ഡിഷ് ടി.വി,എയർടെൽ ഡിജിറ്റൽ ടിവി,വീഡിയോകോൺ D2H,സ്വതന്ത്ര ടിവി (റിലയൻസ് ഡിജിറ്റൽ / ബിഗ് ടിവി),സൂര്യൻ നേരിട്ട്,ഡിഡി ഫ്രീ ഡിഷ്,ഏഷ്യാനെറ്റ്,DEN,ഹാത്ത്വേ,SITI കേബിൾ,Other : (Please Specify)",
                        "Kannada": "ಟಾಟಾ ಸ್ಕೈ,ಡಿಶ್ ಟಿವಿ,ಏರ್‌ಟೆಲ್ ಡಿಜಿಟಲ್ ಟಿವಿ,ವಿಡಿಯೋಕಾನ್ D2H,ಸ್ವತಂತ್ರ ಟಿವಿ (ರಿಲಯನ್ಸ್ ಡಿಜಿಟಲ್ / ಬಿಗ್ ಟಿವಿ),ಸೂರ್ಯನ ನೇರ,ಡಿಡಿ ಉಚಿತ ಭಕ್ಷ್ಯ,ಏಷ್ಯಾನೆಟ್,DEN,ಹಾಥ್ವೇ,SITI ಕೇಬಲ್,Other : (Please Specify)",
                        "Gujarati": "ટાટા સ્કાય,ડીશ ટીવી,એરટેલ ડિજિટલ ટીવી,વિડિયોકોન D2H,સ્વતંત્ર ટીવી (રિલાયન્સ ડિજિટલ / બિગ ટીવી),સન ડાયરેક્ટ,ડીડી ફ્રી ડીશ,એશિયાનેટ,ડેન,હેથવે,SITI કેબલ,Other : (Please Specify)",
                        "Telugu": "టాటా స్కై,డిష్ టీవీ,ఎయిర్‌టెల్ డిజిటల్ టీవీ,వీడియోకాన్ D2H,ఇండిపెండెంట్ టీవీ (రిలయన్స్ డిజిటల్ / బిగ్ టీవీ),సన్ డైరెక్ట్,DD ఉచిత డిష్,ఏషియానెట్,డిఇఎన్,హాత్వే,సిటీ కేబుల్,Other : (Please Specify)",
                        "Assamese": "টাটা স্কাই,ডিচ টিভি,এয়াৰটেল ডিজিটেল টিভি,ভিডিঅ'কন ডি2এইচ,স্বতন্ত্ৰ টিভি (ৰিলায়েন্স ডিজিটেল / বিগ টিভি),ছান ডাইৰেক্ট,ডিডি ফ্ৰী ডিচ,এছিয়ানেট,ডিইএন,হাথৱে,চিটিআই কেবল,Other : (Please Specify)",
                        "Marathi": "टाटा स्काय,डिश टीवी,एअरटेल डिजिटल टीवी,वीडियोकॉन D2H,इंडिपेंडेंट टीवी (रिलायंस डिजिटल/बिग टीवी),सन डायरेक्ट,डीडी फ्री डिश,एशियानेट,डेन,हॅथवे,सिटी केबल,Other : (Please Specify)",
                        "Oriya": "ଟାଟା ସ୍କାଏ,ଡିଶ ଟିଭି,ଏୟାରଟେଲ ଡିଜିଟାଲ ଟିଭି,ଭିଡିଓକନ୍ ଡି2ଏଚ୍,ସ୍ୱତନ୍ତ୍ର ଟିଭି (ରିଲାଏନ୍ସ ଡିଜିଟାଲ / ବିଗ୍ ଟିଭି),ସନ ଡିରେକଟ,ଡିଡି ଫ୍ରି ଡିଶ,ଏସିଆନେଟ୍,ଡେନ୍,ହାଥୱେ,ସିଟି କେବୁଲ୍,Other : (Please Specify)",
                        "Bengali": "টাটা স্কাই,ডিশ টিভি,এয়ারটেল ডিজিটাল টিভি,ভিডিওকন ডি2এইচ,ইন্ডিপেন্ডেন্ট টিভি (রিলায়েন্স ডিজিটাল/বিগ টিভি),সূর্য সরাসরি,ডিডি ফ্রি ডিশ,এশিয়ানেট,ডেইন,হ্যাথওয়ে,এসআইটিআই কেবল,Other : (Please Specify)",
                        "Tamil": "டாடா ஸ்கை,டிஷ் டிவி,ஏர்டெல் டிஜிட்டல் டிவி,வீடியோகான் டி2எச்,சுதந்திர தொலைக்காட்சி (ரிலையன்ஸ்டிஜிட்டல் / பிக் டிவி),சன் டைரக்ட்,டிடி ஃப்ரீ டிஷ்,ஏசியானெட்,தீன்,இடைவழி,எஸ்ஐடி கேபிள்,Other : (Please Specify)",
                        "Hindi": "टाटा स्काई,डिश टीवी,एयरटेल डिजिटल टीवी,वीडियोकॉन D2H,इंडिपेंडेंट टीवी (रिलायंस डिजिटल/बिग टीवी),सन डायरेक्ट,डीडी फ्री डिश,एशियानेट,डेन,हेथवे,सिटी केबल,Other : (Please Specify)"
                    },
                    "displayLogic": {
                        "relatedId": 22,
                        "relatedQuestionSequence": 22,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 23
                },
                {
                    "id": "24",
                    "code": "Q18",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q17. Please select up to what level have you studied?",
                        "Malayalam": "Q17. നിങ്ങൾ ഏത് തലം വരെ പഠിച്ചുവെന്ന് തിരഞ്ഞെടുക്കുക?",
                        "Kannada": "Q17. ದಯವಿಟ್ಟು ನೀವು ಯಾವ ಮಟ್ಟಕ್ಕೆ ಅಧ್ಯಯನ ಮಾಡಿದ್ದೀರಿ ಎಂಬುದನ್ನು ಆಯ್ಕೆ ಮಾಡಿ?",
                        "Gujarati": "Q17. કૃપા કરીને તમે કયા સ્તર સુધી અભ્યાસ કર્યો છે તે પસંદ કરો?",
                        "Telugu": "Q17. మీరు ఏ స్థాయి వరకు చదివారో దయచేసి ఎంచుకోండి?",
                        "Assamese": "Q17. অনুগ্ৰহ কৰি আপুনি কোন স্তৰলৈকে অধ্যয়ন কৰিছে বাছনি কৰক?",
                        "Marathi": "Q17. आपण कोणत्या स्तरापर्यंत शिक्षण घेतले आहे हे कृपया निवडा?",
                        "Oriya": "Q17। ଦୟାକରି ଆପଣ କେଉଁ ସ୍ତରପର୍ଯ୍ୟନ୍ତ ଅଧ୍ୟୟନ କରିଛନ୍ତି ତାହା ଚୟନ କରନ୍ତୁ?",
                        "Bengali": "Q17. আপনি কোন স্তরে পড়াশোনা করেছেন তা দয়া করে নির্বাচন করুন?",
                        "Tamil": "Q17. நீங்கள் எந்த நிலை வரை படித்தீர்கள் என்பதைத் தேர்ந்தெடுக்கவும்?",
                        "Hindi": "Q17.कृपया चयन करें कि आपने किस स्तर तक पढ़ाई की है? "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "School upto 4 years/No schooling/ Illiterate, School 5-9 years, SSC/HSC, Some college but not graduate (Including diploma), Graduate/Post graduate-general (e.g.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), Graduate/Post graduate-professional (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Telugu": "4 సంవత్సరాల వరకు పాఠశాల/పాఠశాల విద్య లేదు/ నిరక్షరాస్యులు,పాఠశాల 5-9 సంవత్సరాలు,SSC/HSC,కొన్ని కళాశాలలు కానీ గ్రాడ్యుయేట్ కాలేదు (డిప్లొమాతో సహా),గ్రాడ్యుయేట్/పోస్ట్ గ్రాడ్యుయేట్-జనరల్ (ఉదా.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),గ్రాడ్యుయేట్/పోస్ట్ గ్రాడ్యుయేట్-ప్రొఫెషనల్ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Tamil": "4 ஆண்டுகள் வரை பள்ளி/பள்ளிக் கல்வி இல்லை/ படிப்பறிவற்றவர், பள்ளி 5-9 ஆண்டுகள், SSC/HSC, சில கல்லூரிகள் ஆனால் பட்டதாரி இல்லை (டிப்ளமோ உட்பட), பட்டதாரி/முதுகலை-பொது (எ.கா.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), பட்டதாரி/முதுகலைப் பட்டதாரி-தொழில்முறை (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Oriya": "4 ବର୍ଷ ପର୍ୟ୍ୟନ୍ତ ବିଦ୍ୟାଳୟ/ ବିଦ୍ୟାଳୟ ଶିକ୍ଷା ନଥିବା/ ନିରକ୍ଷର,ବିଦ୍ୟାଳୟ 5-9 ବର୍ଷ,ଏସଏସସି/ଏଚଏସସି,କିଛି କଲେଜ କିନ୍ତୁ ଗ୍ରାଜୁଏଟ୍ ନୁହେଁ (ଡିପ୍ଲୋମା ସମେତ),ଗ୍ରାଜୁଏଟ୍/ପୋଷ୍ଟ ଗ୍ରାଜୁଏଟ୍-ଜେନାରେଲ (ଯଥା.. B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ଗ୍ରାଜୁଏଟ୍/ପୋଷ୍ଟ ଗ୍ରାଜୁଏଟ୍-ପ୍ରଫେସନାଲ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Marathi": "४ वर्षांपर्यंतची शाळा/ शालेय शिक्षण नाही/ निरक्षर,शाळा ५-९ वर्षे,एसएससी/एचएससी,काही कॉलेज पण पदवीधर नाहीत (डिप्लोमासह),ग्रॅज्युएट/पोस्ट ग्रॅज्युएट जनरल (जैसे.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ग्रॅज्युएट/पोस्ट ग्रॅज्युएट-प्रोफेशनल (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Malayalam": "4 വർഷം വരെ സ്കൂൾ / സ്കൂൾ വിദ്യാഭ്യാസമില്ല / നിരക്ഷരർ,സ്കൂൾ 5-9 വർഷം,എസ്എസ്സി/എച്ച്എസ്സി,ചില കോളേജ് എന്നാൽ ബിരുദം നേടിയിട്ടില്ല (ഡിപ്ലോമ ഉൾപ്പെടെ),ബിരുദം/ബിരുദാനന്തര-ജനറൽ (ഉദാ. B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ബിരുദം/ബിരുദാനന്തര-പ്രൊഫഷണൽ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Kannada": "4 ವರ್ಷಗಳವರೆಗೆ ಶಾಲೆ/ಶಾಲಾ ಶಿಕ್ಷಣವಿಲ್ಲ/ ಅನಕ್ಷರಸ್ಥ,ಶಾಲೆ 5-9 ವರ್ಷಗಳು,SSC/HSC,ಕೆಲವು ಕಾಲೇಜು ಆದರೆ ಪದವೀಧರರಲ್ಲ (ಡಿಪ್ಲೊಮಾ ಸೇರಿದಂತೆ),ಪದವೀಧರ/ಪದವೀಧರ-ಸಾಮಾನ್ಯ (ಉದಾ.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ಪದವೀಧರ/ಸ್ನಾತಕೋತ್ತರ-ವೃತ್ತಿಪರ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Hindi": "4 वर्ष तक स्कूल/कोई स्कूली शिक्षा नहीं/निरक्षर,स्कूल 5-9 साल,एसएससी/एचएससी,कुछ कॉलेज लेकिन स्नातक नहीं (डिप्लोमा सहित),ग्रेजुएट/पोस्ट ग्रेजुएट-सामान्य (जैसे‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ग्रेजुएट/पोस्ट ग्रेजुएट-प्रोफेशनल (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Gujarati": "4 વર્ષ સુધીની શાળા/કોઈ શાળાકીય શિક્ષણ નથી/નિરક્ષર,શાળા 5-9 વર્ષ,SSC/HSC,કેટલીક કોલેજ પરંતુ સ્નાતક નથી (ડિપ્લોમા સહિત),ગ્રેજ્યુએટ/પોસ્ટ ગ્રેજ્યુએટ-જનરલ (દા.ત.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ગ્રેજ્યુએટ/પોસ્ટ ગ્રેજ્યુએટ-પ્રોફેશનલ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Bengali": "4 বছর পর্যন্ত স্কুল/ স্কুলে পড়াশুনা নেই/ নিরক্ষর,স্কুল 5-9 বছর,এসএসসি/এইচএসসি,কিছু কলেজ কিন্তু স্নাতক নয় (ডিপ্লোমা সহ),স্নাতক/স্নাতকোত্তর-জেনারেল (যেমন‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),স্নাতক/স্নাতকোত্তর-পেশাদার (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Assamese": "4 বছৰলৈকে বিদ্যালয়/স্কুলীয়া শিক্ষা/ নিৰক্ষৰ নহয়,বিদ্যালয় 5-9 বছৰ,এছ.এছ.চি./এইচ.এছ.চি.,কিছুমান মহাবিদ্যালয় কিন্তু স্নাতক নহয় (ডিপ্লোমা সহ),স্নাতক/স্নাতকোত্তৰ-জেনেৰেল (যেনে বি.এ.‚ B.Sc.‚ M.Sc.‚ M.Com),স্নাতক/স্নাতকোত্তৰ-পেছাদাৰী (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify"
                    },
                    "displayLogic": {
                        "relatedId": 22,
                        "relatedQuestionSequence": 22,
                        "relatedAnswer": [
                            "Yes",
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 24
                },
                {
                    "id": "25",
                    "code": "Q19",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q18. Please mention your occupation.",
                        "Malayalam": "Q18. ദയവായി നിങ്ങളുടെ തൊഴിൽ പരാമർശിക്കുക.",
                        "Kannada": "Q18. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಉದ್ಯೋಗವನ್ನು ತಿಳಿಸಿ.",
                        "Gujarati": "Q18. કૃપા કરીને તમારા વ્યવસાયનો ઉલ્લેખ કરો.",
                        "Telugu": "Q18. దయచేసి మీ వృత్తిని పేర్కొనండి.",
                        "Assamese": "Q18. অনুগ্ৰহ কৰি আপোনাৰ জীৱিকাউল্লেখ কৰক।",
                        "Marathi": "Q18. कृपया आपल्या व्यवसायाचा उल्लेख करा.",
                        "Oriya": "Q18। ଦୟାକରି ଆପଣଙ୍କ ବୃତ୍ତି ବିଷୟରେ ଉଲ୍ଲେଖ କରନ୍ତୁ।",
                        "Bengali": "Q18. দয়া করে আপনার পেশার কথা উল্লেখ করুন।",
                        "Tamil": "Q18. தயவுசெய்து உங்கள் தொழிலைக் குறிப்பிடவும்.",
                        "Hindi": "Q18 कृपया अपना व्यवसाय बताएं"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 25
                },
                {
                    "id": "26",
                    "code": "Q20",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q19. Please select all the items that you have at home?",
                        "Malayalam": "Q19. നിങ്ങളുടെ വീട്ടിലുള്ള എല്ലാ ഇനങ്ങളും തിരഞ്ഞെടുക്കുക?",
                        "Kannada": "Q19. ದಯವಿಟ್ಟು ನೀವು ಮನೆಯಲ್ಲಿ ಹೊಂದಿರುವ ಎಲ್ಲಾ ಐಟಂಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ?",
                        "Gujarati": "Q19. કૃપા કરીને તમે ઘરે જે વસ્તુઓ ધરાવો છો તે પસંદ કરો?",
                        "Telugu": "Q19. దయచేసి మీ ఇంట్లో ఉన్న అన్ని వస్తువులను ఎంచుకోండి?",
                        "Assamese": "Q19. অনুগ্ৰহ কৰি আপোনাৰ ঘৰত থকা সকলো বস্তু বাছনি কৰক?",
                        "Marathi": "Q19. कृपया आपल्या घरी असलेल्या सर्व वस्तू निवडा?",
                        "Oriya": "Q19। ଦୟାକରି ଆପଣଙ୍କ ଘରେ ଥିବା ସମସ୍ତ ଆଇଟମ୍ ଚୟନ କରନ୍ତୁ?",
                        "Bengali": "Q19. দয়া করে আপনার বাড়িতে থাকা সমস্ত আইটেম নির্বাচন করুন?",
                        "Tamil": "Q19. உங்கள் வீட்டில் உள்ள அனைத்து பொருட்களையும் தேர்ந்தெடுக்கவும்?",
                        "Hindi": "Q19. कृपया उन सभी वस्तुओं का चयन करें जो आपके घर पर हैं? "
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Two wheeler,Colour TV,Refrigerator,Washing Machine,Personal Computer / Laptop,Car/Jeep/Van,Air Conditioner,Agricultural Land",
                        "Malayalam": "ഇരുചക്രവാഹനം,കളർ ടി.വി,റഫ്രിജറേറ്റർ,അലക്കു യന്ത്രം,പേഴ്സണൽ കമ്പ്യൂട്ടർ / ലാപ്ടോപ്പ്,കാർ/ജീപ്പ്/വാൻ,എയർ കണ്ടീഷണർ,കാർഷിക ഭൂമി",
                        "Kannada": "ದ್ವಿಚಕ್ರ ವಾಹನ,ಬಣ್ಣದ ಟಿವಿ,ರೆಫ್ರಿಜರೇಟರ್,ಬಟ್ಟೆ ಒಗೆಯುವ ಯಂತ್ರ,ವೈಯಕ್ತಿಕ ಕಂಪ್ಯೂಟರ್ / ಲ್ಯಾಪ್ಟಾಪ್,ಕಾರು/ಜೀಪ್/ವ್ಯಾನ್,ಹವಾ ನಿಯಂತ್ರಣ ಯಂತ್ರ,ಕೃಷಿ ಭೂಮಿ",
                        "Gujarati": "ટુ વ્હીલર,કલર ટીવી,રેફ્રિજરેટર,વોશિંગ મશીન,પર્સનલ કોમ્પ્યુટર/લેપટોપ,કાર/જીપ/વાન,એર કન્ડીશનર,ખેતીની જમીન",
                        "Telugu": "ద్విచక్ర వాహనం,కలర్ టీవీ,రిఫ్రిజిరేటర్,వాషింగ్ మెషీన్,వ్యక్తిగత కంప్యూటర్ / ల్యాప్‌టాప్,కారు/జీప్/వ్యాన్,వాతానుకూలీన యంత్రము,వ్యవసాయ భూమి",
                        "Assamese": "দুচকীয়া,ৰঙা টিভি,ৰেফ্ৰিজাৰেটৰ,ধোৱা মেচিন,ব্যক্তিগত কম্পিউটাৰ/লেপটপ,গাড়ী/ জীপ/ ভেন,এয়াৰ কণ্ডিশ্যনাৰ,কৃষিভূমি",
                        "Marathi": "टू व्हीलर,रंगीन टीवी,रेफ्रिजरेटर,वॉशिंग मशीन,पर्सनल कंप्यूटर/लैपटॉप,कार/जीप/वैन,एअर कंडिशनर,कृषि भूमि",
                        "Oriya": "ଟୁ ହୁଇଲର,କଲଲର ଟିଭି,ରେଫ୍ରିଜିରେଟର,ଆଁଶିଙ୍ଗ ମେସିନ,ପର୍ସିନାଲ କମ୍ପ୍ୟୁଟର / ଲାପଟପ,କାର/ଜିପ/ଭାନ୍,ଏୟାର କଣ୍ଡିସନର,ଏଗ୍ରିକଲଚୁଲାର ଲ୍ୟାଡ",
                        "Bengali": "দুই চাকার,রঙিন টিভি,রেফ্রিজারেটর,ওয়াশিং মেশিন,ব্যক্তিগত কম্পিউটার/ল্যাপটপ,গাড়ি/জিপ/ভ্যান,এয়ার কন্ডিশনার,কৃষিজমি",
                        "Tamil": "இரு சக்கர வாகனம்,கலர் டிவி,குளிர்சாதனப் பெட்டி,சலவை இயந்திரம்,பெர்சனல் கம்ப்யூட்டர் / லேப்டாப்,கார் / ஜீப் / வான்,ஏர் கண்டிஷனர்,விவசாய நிலம்",
                        "Hindi": "टू व्हीलर,कलर टीवी,रेफ्रिजरेटर,वॉशिंग मशीन,पर्सनल कंप्यूटर / लैपटॉप,कार/जीप /वैन,एयरकंडीशनर,कृषि भूमि"
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 26
                },
                {
                    "id": "27",
                    "code": "Q21",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q20. Are you the person who contributes maximum to the household income?",
                        "Malayalam": "Q20. ഗാർഹിക വരുമാനത്തിലേക്ക് പരമാവധി സംഭാവന ചെയ്യുന്ന വ്യക്തിയാണോ നിങ്ങൾ?",
                        "Kannada": "Q20. ಮನೆಯ ಆದಾಯಕ್ಕೆ ಗರಿಷ್ಠ ಕೊಡುಗೆ ನೀಡುವ ವ್ಯಕ್ತಿ ನೀವೇ?",
                        "Gujarati": "Q20. શું તમે એ વ્યક્તિ છો જે ઘરની આવકમાં મહત્તમ ફાળો આપે છે?",
                        "Telugu": "Q20. కుటుంబ ఆదాయానికి గరిష్టంగా దోహదపడే వ్యక్తి మీరేనా?",
                        "Assamese": "Q20. আপুনি সেই জন ব্যক্তি নেকি যিয়ে ঘৰুৱা উপাৰ্জনত সৰ্বাধিক অৰিহণা যোগায়?",
                        "Marathi": "Q20. घरगुती उत्पन्नात सर्वाधिक योगदान देणारी व्यक्ती तुम्ही आहात का?",
                        "Oriya": "Q20। ଆପଣ କ'ଣ ସେହି ବ୍ୟକ୍ତି ଯିଏ ପରିବାରର ଆୟରେ ସର୍ବାଧିକ ଯୋଗଦାନ କରନ୍ତି?",
                        "Bengali": "Q20. আপনি কি সেই ব্যক্তি যিনি পরিবারের আয়ের সর্বাধিক অবদান রাখেন?",
                        "Tamil": "Q20. குடும்ப வருமானத்திற்கு அதிக பங்களிப்பை வழங்கும் நபரா நீங்கள்?",
                        "Hindi": "Q20. क्या आप वह व्यक्ति हैं जो घरेलू आय में अधिकतम योगदान देते हैं?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "Yes,No",
                        "Malayalam": "അതെ,ഇല്ല",
                        "Kannada": "ಹೌದು,ಸಂ",
                        "Gujarati": "હા,ના",
                        "Telugu": "అవును,నం",
                        "Assamese": "হয়,নহয়",
                        "Marathi": "होय,नाही",
                        "Oriya": "ହଁ,ନା",
                        "Bengali": "হ্যাঁ,না",
                        "Tamil": "ஆம்,இல்லை",
                        "Hindi": "हाँ,नहीं"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 27
                },
                {
                    "id": "28",
                    "code": "Q22",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q21. Select the education level of the person who contributes maximum to the household income?",
                        "Malayalam": "Q21. ഗാർഹിക വരുമാനത്തിലേക്ക് പരമാവധി സംഭാവന ചെയ്യുന്ന വ്യക്തിയുടെ വിദ്യാഭ്യാസ നിലവാരം തിരഞ്ഞെടുക്കുക?",
                        "Kannada": "Q21. ಮನೆಯ ಆದಾಯಕ್ಕೆ ಗರಿಷ್ಠ ಕೊಡುಗೆ ನೀಡುವ ವ್ಯಕ್ತಿಯ ಶಿಕ್ಷಣ ಮಟ್ಟವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ?",
                        "Gujarati": "Q21. ઘરની આવકમાં મહત્તમ ફાળો આપતી વ્યક્તિના શિક્ષણનું સ્તર પસંદ કરવું છે?",
                        "Telugu": "Q21. కుటుంబ ఆదాయానికి గరిష్టంగా దోహదపడే వ్యక్తి యొక్క విద్యా స్థాయిని ఎంచుకోండి?",
                        "Assamese": "Q21. ঘৰুৱা উপাৰ্জনত সৰ্বাধিক অৰিহণা যোগোৱা ব্যক্তিজনৰ শিক্ষাৰ স্তৰ বাছনি কৰিব নেকি?",
                        "Marathi": "Q21. घरगुती उत्पन्नात सर्वाधिक योगदान देणाऱ्या व्यक्तीची शैक्षणिक पातळी निवडा?",
                        "Oriya": "Q21. ଘରର ଆୟରେ ସର୍ବାଧିକ ଯୋଗଦାନ କରୁଥିବା ବ୍ୟକ୍ତିଙ୍କ ଶିକ୍ଷା ସ୍ତର ଚୟନ କରନ୍ତୁ?",
                        "Bengali": "Q21. পরিবারের উপার্জনে সর্বাধিক অবদান রাখে এমন ব্যক্তির শিক্ষার স্তর নির্বাচন করুন?",
                        "Tamil": "Q21. வீட்டு வருமானத்திற்கு அதிக பங்களிக்கும் நபரின் கல்வி மட்டத்தைத் தேர்ந்தெடுக்கவும்?",
                        "Hindi": "Q21. उस व्यक्ति का शिक्षा स्तर चुनें जो घरेलू आय में अधिकतम योगदान देता है?"
                    },
                    "questionsub": {
                        "English": "",
                        "Malayalam": "",
                        "Kannada": "",
                        "Gujarati": "",
                        "Telugu": "",
                        "Assamese": "",
                        "Marathi": "",
                        "Oriya": "",
                        "Bengali": "",
                        "Tamil": "",
                        "Hindi": ""
                    },
                    "options": {
                        "English": "School upto 4 years/No schooling/ Illiterate, School 5-9 years, SSC/HSC, Some college but not graduate (Including diploma), Graduate/Post graduate-general (e.g.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), Graduate/Post graduate-professional (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Telugu": "4 సంవత్సరాల వరకు పాఠశాల/పాఠశాల విద్య లేదు/ నిరక్షరాస్యులు,పాఠశాల 5-9 సంవత్సరాలు,SSC/HSC,కొన్ని కళాశాలలు కానీ గ్రాడ్యుయేట్ కాలేదు (డిప్లొమాతో సహా),గ్రాడ్యుయేట్/పోస్ట్ గ్రాడ్యుయేట్-జనరల్ (ఉదా.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),గ్రాడ్యుయేట్/పోస్ట్ గ్రాడ్యుయేట్-ప్రొఫెషనల్ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Tamil": "4 ஆண்டுகள் வரை பள்ளி/பள்ளிக் கல்வி இல்லை/ படிப்பறிவற்றவர், பள்ளி 5-9 ஆண்டுகள், SSC/HSC, சில கல்லூரிகள் ஆனால் பட்டதாரி இல்லை (டிப்ளமோ உட்பட), பட்டதாரி/முதுகலை-பொது (எ.கா.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), பட்டதாரி/முதுகலைப் பட்டதாரி-தொழில்முறை (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Oriya": "4 ବର୍ଷ ପର୍ୟ୍ୟନ୍ତ ବିଦ୍ୟାଳୟ/ ବିଦ୍ୟାଳୟ ଶିକ୍ଷା ନଥିବା/ ନିରକ୍ଷର,ବିଦ୍ୟାଳୟ 5-9 ବର୍ଷ,ଏସଏସସି/ଏଚଏସସି,କିଛି କଲେଜ କିନ୍ତୁ ଗ୍ରାଜୁଏଟ୍ ନୁହେଁ (ଡିପ୍ଲୋମା ସମେତ),ଗ୍ରାଜୁଏଟ୍/ପୋଷ୍ଟ ଗ୍ରାଜୁଏଟ୍-ଜେନାରେଲ (ଯଥା.. B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ଗ୍ରାଜୁଏଟ୍/ପୋଷ୍ଟ ଗ୍ରାଜୁଏଟ୍-ପ୍ରଫେସନାଲ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Marathi": "४ वर्षांपर्यंतची शाळा/ शालेय शिक्षण नाही/ निरक्षर,शाळा ५-९ वर्षे,एसएससी/एचएससी,काही कॉलेज पण पदवीधर नाहीत (डिप्लोमासह),ग्रॅज्युएट/पोस्ट ग्रॅज्युएट जनरल (जैसे.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ग्रॅज्युएट/पोस्ट ग्रॅज्युएट-प्रोफेशनल (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Malayalam": "4 വർഷം വരെ സ്കൂൾ / സ്കൂൾ വിദ്യാഭ്യാസമില്ല / നിരക്ഷരർ,സ്കൂൾ 5-9 വർഷം,എസ്എസ്സി/എച്ച്എസ്സി,ചില കോളേജ് എന്നാൽ ബിരുദം നേടിയിട്ടില്ല (ഡിപ്ലോമ ഉൾപ്പെടെ),ബിരുദം/ബിരുദാനന്തര-ജനറൽ (ഉദാ. B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ബിരുദം/ബിരുദാനന്തര-പ്രൊഫഷണൽ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Kannada": "4 ವರ್ಷಗಳವರೆಗೆ ಶಾಲೆ/ಶಾಲಾ ಶಿಕ್ಷಣವಿಲ್ಲ/ ಅನಕ್ಷರಸ್ಥ,ಶಾಲೆ 5-9 ವರ್ಷಗಳು,SSC/HSC,ಕೆಲವು ಕಾಲೇಜು ಆದರೆ ಪದವೀಧರರಲ್ಲ (ಡಿಪ್ಲೊಮಾ ಸೇರಿದಂತೆ),ಪದವೀಧರ/ಪದವೀಧರ-ಸಾಮಾನ್ಯ (ಉದಾ.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ಪದವೀಧರ/ಸ್ನಾತಕೋತ್ತರ-ವೃತ್ತಿಪರ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Hindi": "4 वर्ष तक स्कूल/कोई स्कूली शिक्षा नहीं/निरक्षर,स्कूल 5-9 साल,एसएससी/एचएससी,कुछ कॉलेज लेकिन स्नातक नहीं (डिप्लोमा सहित),ग्रेजुएट/पोस्ट ग्रेजुएट-सामान्य (जैसे‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ग्रेजुएट/पोस्ट ग्रेजुएट-प्रोफेशनल (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Gujarati": "4 વર્ષ સુધીની શાળા/કોઈ શાળાકીય શિક્ષણ નથી/નિરક્ષર,શાળા 5-9 વર્ષ,SSC/HSC,કેટલીક કોલેજ પરંતુ સ્નાતક નથી (ડિપ્લોમા સહિત),ગ્રેજ્યુએટ/પોસ્ટ ગ્રેજ્યુએટ-જનરલ (દા.ત.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),ગ્રેજ્યુએટ/પોસ્ટ ગ્રેજ્યુએટ-પ્રોફેશનલ (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Bengali": "4 বছর পর্যন্ত স্কুল/ স্কুলে পড়াশুনা নেই/ নিরক্ষর,স্কুল 5-9 বছর,এসএসসি/এইচএসসি,কিছু কলেজ কিন্তু স্নাতক নয় (ডিপ্লোমা সহ),স্নাতক/স্নাতকোত্তর-জেনারেল (যেমন‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com),স্নাতক/স্নাতকোত্তর-পেশাদার (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify",
                        "Assamese": "4 বছৰলৈকে বিদ্যালয়/স্কুলীয়া শিক্ষা/ নিৰক্ষৰ নহয়,বিদ্যালয় 5-9 বছৰ,এছ.এছ.চি./এইচ.এছ.চি.,কিছুমান মহাবিদ্যালয় কিন্তু স্নাতক নহয় (ডিপ্লোমা সহ),স্নাতক/স্নাতকোত্তৰ-জেনেৰেল (যেনে বি.এ.‚ B.Sc.‚ M.Sc.‚ M.Com),স্নাতক/স্নাতকোত্তৰ-পেছাদাৰী (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB),Other : Please Specify"
                    },
                    "displayLogic": {
                        "relatedId": 27,
                        "relatedQuestionSequence": 27,
                        "relatedAnswer": [
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 28
                }
            ]
        }
    }
        ;
    useEffect(() => {
        if (word != null || word != "") {

            setLoading(true);

            // call for get api       

            axios.get(`${API_END_POINT.BASE_URL}/api/survey/GetSurveyByCode/${word}`)
                .then(response => {
                    let surveyData = response.data.data;
                    surveyData.survey.questions = surveyData.survey.questions.map(question => { return { ...question, isNavigated: false } })

                    // this code is for dummy data to verify
                    // survey.survey.questions = survey.survey.questions.map(question => { return { ...question, isNavigated: false } })


                    setData(surveyData);
                    // setData(survey);
                    // console.log(response.data.data, "response");

                    if (response.data.data.survey.questions.length) {
                        let displayQuestions = [];
                        let displayLogicQ = []
                        for (let i = 0; i < response.data.data.survey.questions.length; i++) {
                            let question = response.data.data.survey.questions[i];
                            let displayLogic = question.displaylogic;
                            if (!displayLogic) {
                                displayQuestions.push(question);
                            } else {
                                //Handle display logic here
                                displayLogicQ.push(question)
                            }
                        }
                        // console.log(displayQuestions, "checking useEffect")
                        setDisplayQuestions(displayQuestions);
                        if (displayLogicQ.length > 0)
                            setDisplayLogicQuestions(displayLogicQ);

                    }
                    setLoading(false);
                }).catch(ex => {
                    seterrLoading(true);
                });


        }

    }, [])
    return (
        <div className="App">
            {/* {loading && <p>Loading...</p>} */}
            {/* <h4>{word}</h4> */}
            {!loading && displayQuestions.length > 0 ?
                <Questionnaire survey={data}
                    Latitude={Latitude}
                    Longitude={Longitude}
                    wholeWord={wholeWord}
                />
                : <InValidURL msg={InvalidURLMsge}
                    errLoading={errLoading}
                />}
        </div>
    );
}
