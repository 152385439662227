import React, { useState, useEffect } from 'react';
import HomeAppComponent from '../HomeAppComponent';
import LocationAndDate from './LocationAndDate';
import { InValidURL } from './InValidURL';

const MainComponent = (props) => {
  const [word, setWord] = useState(null);
  const [wholeWord, setWholeWord] = useState(props.param || props.match.params.param); // Use props.param for query, props.match.params.param for path param
  const InvalidURLMsge = "There is no data with mentioned url,</br> please provide a valid one";

  useEffect(() => {
    // Extract first two letters from the given URL
    const url = props.param || props.match.params.param; // Fallback to query param if route param is not available
    if (url) {
      const extractedWord = url.substr(0, 2); // Extract first 2 letters
      setWord(extractedWord);
    }
  }, [props.param, props.match.params.param]); // Re-run effect if either param changes

  return (
    <div>
      {word != null ? (
        <LocationAndDate word={word} wholeWord={wholeWord} />
      ) : (
        // <div>Loading1...</div>
        <InValidURL msg={InvalidURLMsge}
          errLoading={true}
        />
      )}
    </div>
  );
};

export default MainComponent;
