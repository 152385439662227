import React, { useState, useEffect, useRef } from "react";
import Footer from "../Footer//Footer";
import { Container, Row, Col, Form, FormControl, FormCheck, Button } from "react-bootstrap";
import './Questionnaire.css';
import { InValidURL } from "./InValidURL";
import ErrorComponent from "./ErrorMessage";
import { API_END_POINT } from "../config";

const Questionnaire = ({ survey, Latitude, Longitude, wholeWord }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(survey?.survey?.questions[0]?.questionSequence);
    const [answers, setAnswers] = useState({});
    const [showBackButton, setShowBackButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedNPSValue, setSelectedNPSValue] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [successScreen, setSuccessScreen] = useState(false);
    const [englishAnswerSingleChoice, setEnglishAnswerSingleChoice] = useState('');
    const [englishAnswerMChoice, setEnglishAnswerMChoice] = useState([]);
    const [responseID, setResponseID] = useState(0);
    const [colHeight, setColHeight] = useState(0);
    const [otherTextBox, setOtherTextBox] = useState(false);
    const [otherBoxText, setotherBoxText] = useState('');
    const textareaRef = useRef(null);
    const ThankYouMsge = "Thank You!</br>Your feedback is valuable to us."
    const OtherTextLanguages = ["Other :", "ఇతర :", "আন :", "অন্যান্য :", "અન્ય :", "अन्य :",
        "ಇತರರು :", "മറ്റുള്ളവ :", "इतरांनो :", "ଅନ୍ୟ :", "மற்றவை :"]
    useEffect(() => {
        if (survey &&
            survey.survey.questions &&
            survey.survey.questions[0] && survey.survey.questions[0]?.hide == true) {
            setCurrentQuestionIndex(survey.survey.questions[1].questionSequence);
        }
        else {
            setCurrentQuestionIndex(survey.survey.questions[0].questionSequence);
        }

    }, [])

    const currentQuestion =
        survey &&
        survey.survey.questions &&
        survey.survey.questions.find((question) => question.id == currentQuestionIndex);

    // const options = currentQuestion && currentQuestion?.options.split(",");
    const options = currentQuestionIndex == 1
        ? currentQuestion?.options['English']?.split(",")
        : currentQuestion?.options[survey.survey.questions[0].answerText] != undefined
            ? currentQuestion?.options[survey.survey.questions[0].answerText]?.split(",")
            : currentQuestion?.options["English"]?.split(",")
    // get screen height 

    useEffect(() => {
        function updateColHeight() {
            const screenHeight = window.innerHeight;
            const colHeight = screenHeight - 225 // adjust 200 according to your needs
            setColHeight(colHeight);
        }
        updateColHeight();
        window.addEventListener('resize', updateColHeight);
        return () => window.removeEventListener('resize', updateColHeight);
    }, []);

    // Use useEffect to focus the text area element when it appears
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [otherTextBox]);

    useEffect(() => {
        // setShowBackButton(currentQuestionIndex > 0);
    }, [currentQuestionIndex]);

    useEffect(() => {
        if ((currentQuestion?.questiontype === "SingleChoice")
            // && (currentQuestionIndex !== survey.survey.questions.length - 1)
            && (currentQuestionIndex !== 1)
            && (englishAnswerSingleChoice !== null && englishAnswerSingleChoice !== "")
            && otherTextBox == false
        ) {
            setTimeout(() => {
                handleNextClick();
                // document.querySelector('.AnswersBody').style.opacity = 1;
            }, 300);
        }
    }, [englishAnswerSingleChoice])


    useEffect(() => {
        if (selectedNPSValue !== null && selectedNPSValue !== "" && currentQuestionIndex !== 2) {
            setTimeout(() => {
                handleNextClick();
                // document.querySelector('.AnswersBody').style.opacity = 1;
            }, 300);
        }

    }, [selectedNPSValue]);

    const handleOtherSelection = (event) => {
        const answer = event.target.value;
        setotherBoxText(answer);
    }

    const handleOptionSelection = (event, type) => {
        const answer = event.target.value;
        // document.querySelector('.AnswersBody').style.opacity = 0;
        switch (type) {
            case "SingleChoice":
                currentQuestion.answerText = answer;
                let findEnglishAnswer = currentQuestion
                    ?.options["English"].split(",")[currentQuestion
                        ?.options[survey.survey.questions[0].answerText]
                        ?.split(",").findIndex(te => te == answer)
                ] != undefined
                    ? currentQuestion
                        ?.options["English"].split(",")[currentQuestion
                            ?.options[survey.survey.questions[0].answerText]
                            ?.split(",").findIndex(te => te == answer)
                    ]
                    : currentQuestion
                        ?.options["English"].split(",")[currentQuestion
                            ?.options["English"]
                            ?.split(",").findIndex(te => te == answer)
                    ]
                    ;
                let checkAnswer = findEnglishAnswer && OtherTextLanguages.some(text => findEnglishAnswer.startsWith(text));
                // commented on July 11
                // let checkAnswer = findEnglishAnswer && findEnglishAnswer.match(/^Other :/);
                // let textA = checkAnswer ? checkAnswer[0] : null;
                let textA = checkAnswer || null;

                if (textA == null) {
                    setEnglishAnswerSingleChoice(findEnglishAnswer);
                    setOtherTextBox(false);
                }
                else {
                    setOtherTextBox(true);
                    setEnglishAnswerSingleChoice(findEnglishAnswer);
                }
                event.target.blur();
                break;
            case "OpenText":
                currentQuestion.answerText = answer;
                break;
            case "MultipleChoice":
                let newAnswerText;
                if (event.target.checked) {
                    // Add selected answer to the current answers array
                    newAnswerText = [...currentQuestion.answerText, answer];
                } else {
                    // Remove the deselected answer from the current answers array
                    newAnswerText = currentQuestion.answerText.filter(
                        (option) => option !== answer
                    );
                }
                currentQuestion.answerText = newAnswerText;

                // Find the corresponding English answers for the selected answers
                // let findEnglishAnswers = newAnswerText.map((answer) => {
                //     // Find the index of the answer in the options array of the question
                //     const index =
                //         currentQuestion.options[survey.survey.questions[0].answerText]
                //             ?.split(",")
                //             ?.findIndex((te) => te === answer);

                //     if (
                //         index !== undefined &&
                //         index !== -1 &&
                //         currentQuestion.options["English"]?.split(",")[index]
                //     ) {
                //         // Return the corresponding English answer
                //         return currentQuestion.options["English"]?.split(",")[index];
                //     } else {
                //         // If no corresponding English answer found, return the original answer
                //         return currentQuestion.options["English"]?.split(",")?.find(
                //             (te) => te === answer
                //         );
                //     }
                // });

                // Check if the answer includes the "Other :" option
                // commented on July 11
                // const hasOtherOption = newAnswerText?.some((option) =>
                //     option.includes("Other :")
                // );

                const hasOtherOption = newAnswerText?.some((option) =>
                    OtherTextLanguages.some((text) => option.includes(text))
                );


                if (hasOtherOption) {
                    // Show the other text box
                    setOtherTextBox(true);
                    if (textareaRef.current) {
                        textareaRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                } else {
                    // Hide the other text box
                    setOtherTextBox(false);
                }

                // Retrieve the options for the current question
                let currentQOptions =
                    currentQuestion?.options[survey.survey.questions[0].answerText] !== undefined
                        ? currentQuestion?.options[survey.survey.questions[0].answerText]?.split(",")
                        : currentQuestion?.options["English"]?.split(",");

                // console.log(currentQOptions);

                // Create a modified array based on the current answers array
                let modifiedMultiArr = currentQOptions.map((option) =>
                    newAnswerText.includes(option) ? option : ""
                );

                // console.log(modifiedMultiArr, "multi choice");

                // Map the modified array to retrieve the final English answers
                let finalAnswers = modifiedMultiArr.map((answer, index) => {
                    return answer
                        ? currentQuestion.options["English"].split(",")[index]
                        : "";
                });

                setEnglishAnswerMChoice(finalAnswers);
                break;
            case "NPS":
                currentQuestion.answerText = answer;
                break;
            default:
                break;
        }
        setAnswers({ ...answers, [currentQuestionIndex]: answer });
    };

    const handleNextClick = async () => {
        if (currentQuestionIndex === 1 && currentQuestion.answerText != '') {
            let now = new Date();
            let currentStartDate = ExtractDateAndTime(now, true);
            setStartDate(currentStartDate);
        }

        if (currentQuestion.questiontype === "NPS" && selectedNPSValue != null) {
            currentQuestion.answerText = selectedNPSValue;
        }

        if (currentQuestion.questiontype == "MultipleChoice" && otherBoxText != "") {
            const targetString = "Other :";
            const replacementString = "Other :" + otherBoxText;

            const index = englishAnswerMChoice.findIndex(option => option.includes(targetString));

            if (index !== -1) {
                englishAnswerMChoice.splice(index, 1, replacementString);
            }
        }

        // opentext validation for age question
        if (currentQuestion.questiontype === "OpenText"
            && currentQuestion.answerText != ""
            && currentQuestion?.NumberRange) {
            if (isNaN(currentQuestion.answerText)) {
                setErrorMessage("Please specify a Numeric value!!");
                return;
            }
            else {
                if (Number(currentQuestion.answerText) < currentQuestion?.NumberRange[0]
                    || Number(currentQuestion.answerText) > currentQuestion?.NumberRange[1]) {
                    // setErrorMessage(`Please enter correct range between 
                    // ${currentQuestion?.NumberRange[0]} and  ${currentQuestion?.NumberRange[1]}`);
                    // setErrorMessage(currentQuestion?.NumberRange[2]);
                    if (currentQuestion?.NumberRange[2]) {
                        // If NumberRange[2] is present, set it as the error message
                        setErrorMessage(currentQuestion?.NumberRange[2]);
                    } else {
                        // If NumberRange[2] is not present, set the message for the range between [0] and [1]
                        setErrorMessage(`Please enter the correct range between 
                                         ${currentQuestion?.NumberRange[0]} and ${currentQuestion?.NumberRange[1]}`);
                    }
                    return;
                }
            }
        }
        else if (currentQuestion.questiontype === "OpenText"
            && currentQuestion.answerText.length >= 1024) {
            setErrorMessage("Answer should contain max 1024 characters");
            return;
        }

        if (currentQuestion.answerText == ""
        ) {
            setErrorMessage("Answer cannot be empty !!");
            return;
        }
        else {
            let currentObj = { ...currentQuestion };
            let now = new Date();
            let responsedate = ExtractDateAndTime(now, false);
            let respondentcode = wholeWord;
            let surveylocation = Latitude + ',' + Longitude;
            let responseid = currentQuestionIndex == 1 ? 0 : responseID;
            let answersObj = {};
            answersObj.surveyid = survey.surveyid;
            answersObj.surveycode = survey.surveycode;
            answersObj.surveyname = survey.surveyname;
            answersObj.surveyheading = survey.surveyheading;

            const questionsObj = {
                id: currentObj.id,
                code: currentObj.code,
                answerText: currentObj.questiontype == "SingleChoice"
                    ? englishAnswerSingleChoice != ""
                        ? englishAnswerSingleChoice
                        : currentObj.answerText
                    : currentObj.questiontype == "MultipleChoice"
                        ? englishAnswerMChoice.join(",")
                        : currentObj.answerText,
            }

            if (otherTextBox == true && otherBoxText == '') {
                setErrorMessage("Answer cannot be empty !!");
                return;
            }
            if (currentQuestion.questiontype == "SingleChoice" && otherBoxText != "") {
                questionsObj.answerText = "Other :" + otherBoxText;
            }

            answersObj.questions = questionsObj;
            survey.survey.questions.find((question) => question.id == currentQuestionIndex).answerText = questionsObj.answerText;
            let postObject = {
                responseid,
                responsedate, respondentcode, surveylocation,
                answer: answersObj
            }
            // console.log(postObject, "Post call Answer")
            // submit the answers
            // url needs to be changed
            if (currentQuestionIndex == 1) {
                // https://api.pvaluesurvey.in/api/Response/PostResponse
                await fetch(`${API_END_POINT.BASE_URL}/api/Response/PostResponse`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({ data: 'some data' })
                    body: JSON.stringify(postObject)
                })
                    .then(response => response.json())
                    .then(data => {
                        // setResponse(data);
                        if (currentQuestionIndex !== survey.survey.questions.length - 1) {
                            setResponseID(data.data.Status);
                            // console.log(data.data, "return Response");
                            // setSuccessScreen(true);
                            // navigationLogic been reused for the below 
                            handleNavigationLogic(currentQuestion, setSuccessScreen, selectedNPSValue, setSelectedNPSValue, setOtherTextBox, setotherBoxText, setErrorMessage, survey, currentQuestionIndex, setCurrentQuestionIndex, englishAnswerSingleChoice, setEnglishAnswerSingleChoice);
                        }
                        else {
                            setSuccessScreen(true);
                            if (selectedNPSValue !== null && selectedNPSValue !== "") {
                                setSelectedNPSValue("");
                            }

                        }

                    });
            }
            else {
                // Trigger the API call in the background
                const apiPromise = fetch(`${API_END_POINT.BASE_URL}/api/Response/PostResponse`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postObject)
                }).then(response => response.json());

                // Updating the UI with the new data when the promise resolves
                apiPromise.then(data => {
                    // Updating the state with the new data
                    setResponseID(data.data.Status);
                    // console.log(data.data, "return Response");
                });

                // Navigate to the next question immediately
                if (currentQuestionIndex !== survey.survey.questions.length) {

                    handleNavigationLogic(currentQuestion, setSuccessScreen, selectedNPSValue, setSelectedNPSValue, setOtherTextBox, setotherBoxText, setErrorMessage, survey, currentQuestionIndex, setCurrentQuestionIndex, englishAnswerSingleChoice, setEnglishAnswerSingleChoice);

                }
                else {
                    setSuccessScreen(true);
                    if (selectedNPSValue !== null && selectedNPSValue !== "") {
                        setSelectedNPSValue("");
                    }

                }

            }

            // uncomment this and comment above api calls code
            // this calls without sending the data to api and navigates
            //  handleNavigationLogic(currentQuestion, setSuccessScreen, selectedNPSValue, setSelectedNPSValue, setOtherTextBox, setotherBoxText, setErrorMessage, survey, currentQuestionIndex, setCurrentQuestionIndex, englishAnswerSingleChoice, setEnglishAnswerSingleChoice);



        }

    };

    const handleBackClick = () => {
        let navigator = currentQuestionIndex - 1;
        let difference = survey.survey.questions[navigator].displayLogic
            ? survey.survey.questions.findIndex(x => x.displayLogic) - 1
            : navigator;
        setCurrentQuestionIndex(difference);
        setErrorMessage("");
    };

    const handleSubmitClick = async () => {
        let now = new Date();
        let responsedate = ExtractDateAndTime(now, false);
        let surveyendtime = ExtractDateAndTime(now, true);
        let respondentcode = wholeWord;
        let surveystarttime = startDate;
        let surveylocation = Latitude + ',' + Longitude
        let postObject = {
            responsedate, respondentcode, surveystarttime, surveyendtime, surveylocation,
            answer: survey
        }

        // submit the answers
        // url needs to be changed

        await fetch('https://cidashboards.nielseniq.com/SurveyAPI/api/Response/PostResponse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({ data: 'some data' })
            body: JSON.stringify(postObject)
        })
            .then(response => response.json())
            .then(data => {
                // setResponse(data);
                setSuccessScreen(true);
            });
    };

    const displayedQuestion = survey?.survey?.questions.find(x => x.id == currentQuestion?.displayLogic?.relatedId && x.questionSequence == currentQuestion?.displayLogic?.relatedQuestionSequence)?.answerText;

    return (
        <>
            {!successScreen ?
                <div>
                    <div className="container-fluid h-100">
                        <Row className="h-100">
                            {/* <div className="title">
                                <h3>{survey.surveyheading}</h3>
                            </div> */}
                            <div style={survey?.surveyheadingBackground}>
                                <p style={survey?.surveyheadingForeground}>
                                    {survey.surveyheading}
                                </p>
                            </div>
                            <div>

                                {displayedQuestion === undefined
                                    ? currentQuestion && currentQuestion.question && currentQuestion.isMandatory &&
                                    <>
                                        <>
                                            <p style={{ marginTop: '10px' }}>
                                                <span className="question-text" dangerouslySetInnerHTML={{
                                                    __html: currentQuestionIndex == 1
                                                        ? currentQuestion?.question['English']
                                                        : currentQuestion?.question[survey.survey.questions[0].answerText] != undefined
                                                            ? currentQuestion?.question[survey.survey.questions[0].answerText]
                                                            : currentQuestion?.question["English"]
                                                }} />
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                        </>
                                    </>
                                    :
                                    <>
                                        <>
                                            <p style={{ marginTop: '10px' }}>
                                                <span className="question-text" dangerouslySetInnerHTML={{
                                                    __html: currentQuestionIndex == 1
                                                        ? currentQuestion?.question['English']
                                                        : currentQuestion?.question[survey.survey.questions[0].answerText] != undefined
                                                            ? currentQuestion?.question[survey.survey.questions[0].answerText].replace(/{([^}]+)}/g, displayedQuestion)
                                                            : currentQuestion?.question["English"].replace(/{([^}]+)}/g, displayedQuestion)
                                                }} />
                                                <span style={{ color: 'red' }}>*</span>
                                            </p>
                                        </>
                                    </>
                                }

                                {currentQuestion && currentQuestion.questionsub && (
                                    <p style={{ marginTop: '10px' }}>
                                        <span className="question-subtext" dangerouslySetInnerHTML={{
                                            __html: currentQuestionIndex == 1 ? currentQuestion?.questionsub['English']
                                                : currentQuestion?.questionsub[survey.survey.questions[0].answerText] != undefined
                                                    ? currentQuestion?.questionsub[survey.survey.questions[0].answerText]
                                                    : currentQuestion?.questionsub["English"]
                                        }} />


                                    </p>
                                )}

                            </div>
                            {errorMessage &&
                                <ErrorComponent message={errorMessage} />}

                            <div>
                                <Col style={{
                                    overflowY: 'scroll',
                                    borderRight: '1.5px solid #d3caca',
                                    height: colHeight,
                                }}>

                                    <div className="AnswersBody">

                                        {currentQuestion && currentQuestion.questiontype === "SingleChoice" && (
                                            <Col>
                                                <Form>
                                                    <Row>
                                                        {options.map((option, index) => (
                                                            <Col
                                                                key={index}
                                                                md={currentQuestion.columns ? currentQuestion.columns : currentQuestionIndex == 1 ? 5 : 11}
                                                                sm={currentQuestion.columns ? currentQuestion.columns : currentQuestionIndex == 1 ? 5 : 11}
                                                                xs={currentQuestion.columns ? currentQuestion.columns : currentQuestionIndex == 1 ? 5 : 11}
                                                                className="option-item"
                                                                onClick={(e) => {
                                                                    // Trigger the radio button click event when the column space is clicked
                                                                    const radioButton = document.getElementById(`radio-${currentQuestion.code}-${index}`);
                                                                    radioButton.click();
                                                                }}
                                                            >
                                                                <label htmlFor={`radio-${currentQuestion.code}-${index}`} className="option-item-text">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label={option}
                                                                        name={currentQuestion.code}
                                                                        value={option}
                                                                        id={`radio-${currentQuestion.code}-${index}`}
                                                                        checked={currentQuestion.answerText === option}
                                                                        onChange={(e) => {
                                                                            handleOptionSelection(e, currentQuestion.questiontype);
                                                                        }}
                                                                    />
                                                                </label>
                                                            </Col>
                                                        ))}
                                                        {otherTextBox && (
                                                            <Form.Control
                                                                as="textarea"
                                                                style={{
                                                                    height: '30px',
                                                                    fontSize: '17px',
                                                                    width: '80vw', // set width to 90% of viewport width
                                                                    marginLeft: '10px',
                                                                }}
                                                                className="open-text-answer"
                                                                placeholder="Please specify your answer"
                                                                onChange={(e) => {
                                                                    handleOtherSelection(e);
                                                                }}
                                                                ref={textareaRef}
                                                            />
                                                        )}
                                                    </Row>
                                                </Form>
                                            </Col>
                                        )}



                                        {currentQuestion && currentQuestion.questiontype === "OpenText"
                                            // && displayedQuestion && currentQuestion?.displayLogic.relatedAnswer.includes(Number(displayedQuestion))
                                            && (

                                                <Col>
                                                    <Form.Control as="textarea"
                                                        className="open-text"
                                                        placeholder="Enter your answer here"
                                                        name={currentQuestion.code}
                                                        value={currentQuestion.answerText}
                                                        onChange={(e) => {
                                                            handleOptionSelection(e, currentQuestion.questiontype);
                                                        }}
                                                    />
                                                </Col>
                                            )}

                                        {currentQuestion.questiontype === "NPS" && (
                                            <Col>
                                                <Form>
                                                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                                                        <p style={{ fontWeight: 'bold', width: '30%', textAlign: 'start' }}>Not at all likely</p>
                                                        <p style={{ width: '40%' }}></p>
                                                        <p style={{ fontWeight: 'bold', width: '30%', textAlign: 'end' }}>Extremely likely</p>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        {npsData.map(({ value, className, text, textMargin }, index) => (
                                                            <div className="d-flex flex-column align-items-center" style={{ width: `${100 / npsData.length}%` }}>
                                                                <Button
                                                                    key={value}
                                                                    style={{ fontSize: '14pt', padding: '8px', margin: '2px', textAlign: 'center' }}
                                                                    className={`btn ${className} ${selectedNPSValue === value ? 'btn-light' : ''}`}
                                                                    onClick={() => {
                                                                        setSelectedNPSValue(value);
                                                                    }}
                                                                >
                                                                    {value}
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Form>
                                            </Col>
                                        )}



                                        {currentQuestion && currentQuestion.questiontype === "MultipleChoice" && (

                                            <div>
                                                {options.map((option, index) => (
                                                    <Col
                                                        key={index}
                                                        style={{
                                                            "border": "black", "border-style": "groove",
                                                            "padding": "5px", "margin": "8px",
                                                            "borderRadius": '7px', "cursor": "pointer",
                                                            'fontSize': '17px'
                                                        }}
                                                        onClick={() => {
                                                            const checkbox = document.getElementsByName(currentQuestion.code)[index];
                                                            checkbox.checked = !checkbox.checked;
                                                            handleOptionSelection({ target: checkbox }, currentQuestion.questiontype);
                                                        }}
                                                    >
                                                        <Form.Check
                                                            key={index}
                                                            type="checkbox"
                                                            label={option}
                                                            name={currentQuestion.code}
                                                            value={option}
                                                            checked={currentQuestion.answerText.includes(option)}
                                                            onChange={(e) => {
                                                                e.target.checked = !e.target.checked;
                                                                handleOptionSelection(e, currentQuestion.questiontype);
                                                            }}
                                                        />
                                                    </Col>
                                                ))}
                                                {otherTextBox && (
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{
                                                            height: '30px',
                                                            fontSize: '17px',
                                                            width: '80vw', // set width to 90% of viewport width
                                                            marginLeft: '10px',
                                                            marginBottom: '15px'
                                                        }}
                                                        className="open-text-answer"
                                                        placeholder="Please specify your answer"
                                                        onChange={(e) => {
                                                            handleOtherSelection(e);
                                                        }}
                                                        ref={textareaRef}
                                                    />
                                                )}
                                            </div>

                                        )}

                                    </div>

                                </Col>
                            </div>
                        </Row >

                    </div >
                    <Footer
                        currentQuestion={currentQuestion}
                        currentQuestionIndex={currentQuestionIndex}
                        survey={survey}
                        handleNextClick={handleNextClick}
                        handleBackClick={handleBackClick}
                        handleSubmitClick={handleSubmitClick}
                    />
                </div >


                : <InValidURL msg={ThankYouMsge}

                    errLoading={true} />}
        </>
    );
};

const npsData = [
    { value: '0', className: 'btn-danger margin-right', text: 'Not at all likely' },
    { value: '1', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '2', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '3', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '4', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '5', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '6', className: 'btn-danger margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '7', className: 'btn-warning margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '8', className: 'btn-warning margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '9', className: 'btn-success margin-right', text: '', textMargin: { marginTop: '44px' } },
    { value: '10', className: 'btn-success margin-eftl', text: 'Extremely likely' }
];


export default Questionnaire;

function handleNavigationLogic(currentQuestion, setSuccessScreen, selectedNPSValue, setSelectedNPSValue, setOtherTextBox, setotherBoxText, setErrorMessage, survey, currentQuestionIndex, setCurrentQuestionIndex, englishAnswerSingleChoice, setEnglishAnswerSingleChoice) {
    if ((currentQuestion.stopLogic
        && currentQuestion.stopLogic.relatedAnswer.includes(currentQuestion.answerText))
        || currentQuestionIndex === survey.survey.questions.length) {
        setSuccessScreen(true);
        if (selectedNPSValue !== null && selectedNPSValue !== "") {
            setSelectedNPSValue("");
        }
        setOtherTextBox(false);
        setotherBoxText("");
        setErrorMessage("");
    }
    else {
        // this is for new logic implementation
        const navigator = handleNavigation(currentQuestionIndex, survey, setCurrentQuestionIndex, currentQuestion);
        if (navigator <= survey.survey.questions.length) {
            setCurrentQuestionIndex(navigator);
        }
        else {
            setSuccessScreen(true);
            return;
        }

        // find the answer for question which has {code} in the question
        // and replace it with code answer here


        // regex to find in question where a question has{QPR31} this type
        const navigatorQuestion = survey.survey.questions[navigator];
        let match, qValue;

        if (navigatorQuestion && navigatorQuestion?.question) {
            if (survey.survey.questions[0].answerText in navigatorQuestion?.question) {
                match = navigatorQuestion?.question[survey.survey.questions[0].answerText].match(/{(.*?)}/);
            } else if ('English' in navigatorQuestion.question) {
                match = navigatorQuestion?.question['English'].match(/{(.*?)}/);
            }
        }

        if (match && match[1]) {
            const matchedQuestion = survey.survey.questions.find(x => x.code === match[1]);
            qValue = matchedQuestion ? matchedQuestion.answerText : null;
        } else {
            qValue = null;
        }

        if (qValue !== null && qValue !== '') {

            if (survey.survey.questions[navigator].
                question[survey.survey.questions[0].answerText] != undefined) {
                survey.survey.questions[navigator].
                    question[survey.survey.questions[0].answerText] = survey.survey.questions[navigator].
                        question[survey.survey.questions[0].answerText]
                        .replace(match[1], qValue).replace(/{|}/g, '')
            }
            else {
                survey.survey.questions[navigator].
                    question["English"] = survey.survey.questions[navigator].
                        question["English"].replace(match[1], qValue).replace(/{|}/g, '');

            }

        }

        // setCurrentQuestionIndex(currentQuestionIndex + 1)
        if (selectedNPSValue !== null && selectedNPSValue !== "") {
            setSelectedNPSValue("");
        }
        if (englishAnswerSingleChoice !== null && englishAnswerSingleChoice !== "") {
            setEnglishAnswerSingleChoice("");
        }
        setOtherTextBox(false);
        setotherBoxText("");
        setErrorMessage("");
    }
}

function handleNavigation(currentQuestionIndex, survey, setCurrentQuestionIndex, currentQuestion) {
    let valueN = currentQuestionIndex + 1;

    if (currentQuestion.questionSequence == currentQuestionIndex) {
        currentQuestion.isNavigated = true;
    }

    let surveyQuestions = survey.survey.questions;
    let nextQuestion = surveyQuestions.find((question) => question.questionSequence == valueN);

    if (nextQuestion?.displayLogic) {

        let parentQuestion = surveyQuestions.find(x => x.id == nextQuestion?.displayLogic?.relatedId);
        let parentAnswer = parentQuestion.answerText;

        let conditionCheck;

        if (parentQuestion.questiontype == "NPS") {
            conditionCheck = nextQuestion?.displayLogic?.relatedId == parentQuestion.id && nextQuestion?.displayLogic?.relatedAnswer.includes(parseInt(parentAnswer));
        }
        else {
            conditionCheck = nextQuestion?.displayLogic?.relatedId == parentQuestion.id && nextQuestion?.displayLogic?.relatedAnswer.includes(parentAnswer);
        }


        if (conditionCheck == true) {
            return nextQuestion.questionSequence;
        }
        else {

            let newN = valueN + 1;
            let startN = valueN + 1

            for (let i = startN; i < surveyQuestions.length; i++) {
                let nextQuestion = surveyQuestions.find((question) => question.questionSequence == i);
                if (nextQuestion?.displayLogic) {
                    let parentQuestion = surveyQuestions.find(x => x.id == nextQuestion?.displayLogic?.relatedId);
                    let parentAnswer = parentQuestion.answerText;

                    if (parentQuestion.questiontype == "NPS") {
                        conditionCheck = nextQuestion?.displayLogic?.relatedId == parentQuestion.id && nextQuestion?.displayLogic?.relatedAnswer.includes(parseInt(parentAnswer));
                    }
                    else {
                        conditionCheck = nextQuestion?.displayLogic?.relatedId == parentQuestion.id && nextQuestion?.displayLogic?.relatedAnswer.includes(parentAnswer);
                    }

                    if (conditionCheck == true) {
                        break;
                    }
                    else {
                        newN = newN + 1;
                    }
                }
                else {
                    break;
                }
            }
            return newN;
        }
    }
    else {
        return valueN;
    }
}

function ExtractDateAndTime(now, timeRequired) {
    let year = now.getFullYear();
    let month = now.getMonth();
    let day = now.getDate();

    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    let currentStartDate = timeRequired == true ?
        `${year}-${month + 1}-${day} ${hours}:${minutes}:${seconds}`
        : `${year}-${month + 1}-${day}`;
    return currentStartDate;
}

