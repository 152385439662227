import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";

const Footer = ({
    currentQuestion,
    currentQuestionIndex,
    survey,
    handleNextClick,
    handleBackClick,
    handleSubmitClick,
}) => {
    const [widthSize, setWidth] = useState(0);
    const [showBackButton, setShowBackButton] = useState(false);

    useEffect(() => {
        setShowBackButton(currentQuestionIndex > 0);
        // caluclate the questions length for progess bar
        setWidth(() => {
            let val = Math.floor((currentQuestionIndex) / survey.survey.questions.length * 100);
            return val;
        });
    }, [currentQuestionIndex]);

    return (
        <div className="d-flex justify-content-between" style={{
            "position": 'absolute', "bottom": "0",
            "justify-content": "center",
            "align-items": "center",
            "height": "50px",
            "width": "100%", "marginBottom": "15px", "backgroundColor": "black",
        }}>
            {/* <Button variant="primary" style={{
                marginLeft: '15px',
                visibility: !showBackButton ? "hidden" : "visible"
            }} onClick={handleBackClick}>
                &lt;
            </Button> */}
            <Button variant="primary" style={{
                marginLeft: '15px',
                visibility: "hidden"
            }} onClick={handleBackClick}>
                &lt;
            </Button>

            <ProgressBar now={widthSize} label={`${widthSize}%`} style={{ width: "60%", marginLeft: "15px" }} />
            {/* {
                currentQuestion &&
                    currentQuestionIndex !== survey.survey.questions.length - 1 ? (
                    <Button variant="primary" onClick={handleNextClick} style={{ marginRight: '15px' }}>
                        &gt;
                    </Button>
                ) : (
                    <Button variant="primary" onClick={handleSubmitClick} style={{ marginRight: '15px' }} >
                        Submit
                    </Button>
                )
            } */}
            {
                currentQuestion &&
                (
                    <Button variant="primary" onClick={handleNextClick} style={{ marginRight: '15px' }}>
                        &gt;
                    </Button>
                )
            }
        </div >
    );
};

export default Footer;
